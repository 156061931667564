import { Wallet, ethers, utils } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import {
  ANKOKUAddress,
  ANTHROAddress,
  APP_NETWORK,
  ETHERSCAN_API_KEY,
  HUMANAddress,
  HUMANMUSICAddress, MUTANT_ALIENS_GENESISAddress, MUTANT_ALIENSAddress,
  PRIVATE_KEY,
  REWARD_TOKEN, YGY_MUTANT_ALIENSAddress,
} from "./constant";
import axios from 'axios';
import {useState} from "react";

const { getAddress } = utils;
const ContractAbi =
  require("artifacts/contracts/ERC721Token.sol/ERC721Token.json").abi;
const ContractERC20Abi =
  require("artifacts/contracts/ERC20Token.sol/ERC20Token.json").abi;

export const getMetamaskSigner = async () => {
  const browserProvider = await detectEthereumProvider();
  if (browserProvider?.isMetaMask !== true) {
    return {
      signer: null,
      wallet_address: null,
    };
  }

  const provider = new ethers.providers.Web3Provider(browserProvider);
  const walletAccounts = (await provider.listAccounts()) || [];
  const walletNetwork = (await provider.getNetwork()) || {};

  return {
    signer: provider?.getSigner(),
    wallet_address: walletAccounts[0],
    network: walletNetwork,
  };
};

export const connectMetamask = async () => {
  try {
    const browserProvider = await detectEthereumProvider();
    if (browserProvider?.isMetaMask !== true) {
      return {
        success: false,
        data: {
          message: "Please install Metamask",
        },
      };
    }

    let walletAccounts;
    let walletNetwork;
    const provider = new ethers.providers.Web3Provider(browserProvider);
    walletAccounts = await provider.listAccounts();
    walletNetwork = (await provider.getNetwork()) || {};
    if (walletAccounts.length === 0 && provider && provider.provider) {
      await provider.provider.request({ method: "eth_requestAccounts" });
      walletAccounts = await provider.listAccounts();
      walletNetwork = (await provider.getNetwork()) || {};
      return {
        signer: provider.getSigner(),
        wallet_address: walletAccounts[0],
        network: walletNetwork,
      };
    } else {
      return {
        signer: provider.getSigner(),
        wallet_address: walletAccounts[0],
        network: walletNetwork,
      };
    }
  } catch (error) {
    return {
      signer: null,
      wallet_address: null,
      network: {},
    };
  }
};

export const checkTokenApproval = async ({
  signer,
  token_address,
  wallet_address,
}) => {
  const contract = new ethers.Contract(token_address, ContractAbi, signer);

  const isApprovalForAll = await contract.isApprovedForAll(
    wallet_address,
    process.env.REACT_APP_STAKING_CONTRACT
  );

  if (!isApprovalForAll) {
    let responseApproval = await contract.setApprovalForAll(
      process.env.REACT_APP_STAKING_CONTRACT,
      true
    );
    await responseApproval.wait();
  }
};

const getVideoThumbnail = video => {
  const canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
  setVideoThumbnail(() => thumbnailDataUrl);

  return thumbnailDataUrl;
};

export const convertNFTImage = (tokenAddress, tokenId, image) => {
  switch (getAddress(tokenAddress)) {
    case ANTHROAddress:
      return `https://ipfs.moralis.io:2053/ipfs/Qmegx6fNNmMgt6RxnReGP9fxu2v3AtFNQXxXAooCNa735U/${tokenId}.png`;
    case HUMANAddress:
      return `https://ipfs.moralis.io:2053/ipfs/QmQt8fcqGCQtpKPZPYTrYyidfbXdujVcf9cGVp86LGQmbU/${tokenId}.png`;
    case ANKOKUAddress:
      return `https://ipfs.moralis.io:2053/ipfs/QmXv89pKdAEQhVWc9hLsgA7VWDJ91L6pXt47iuDqidq54Z/${tokenId}.png`;
    case HUMANMUSICAddress:
      return `https://ipfs.moralis.io:2053/ipfs/QmW36JX1Tpyyxwy2fTSHkJmzJZw9ZTC6oA4FF4kvScQ7cV/${tokenId}.mp4`;
    case MUTANT_ALIENS_GENESISAddress:
      return image;
    case MUTANT_ALIENSAddress:
      return image;
    case YGY_MUTANT_ALIENSAddress:
      return image;
    default:
      return `https://gateway.ipfs.io/${image.replace("ipfs://", "ipfs/")}`;
  }
};

export const transferGameRewardToken = async body => {
  const { amount, receive_address } = body;

  const provider = new ethers.providers.EtherscanProvider(
    APP_NETWORK,
    ETHERSCAN_API_KEY
  );
  const walletPrivateKey = new Wallet(PRIVATE_KEY);
  const wallet = walletPrivateKey.connect(provider);

  const contract = new ethers.Contract(REWARD_TOKEN, ContractERC20Abi, wallet);

  const transaction = await contract.transfer(receive_address, amount);
  await transaction.wait();

  return {
    success: true,
    data: {
      transaction_hash: transaction.hash,
    },
  };
};
