import React, { useState } from "react";
import classNames from 'classnames';
import { utils as ethersUtils } from "ethers";

import 'assets/css/roulette.css';
import { Button, Image, Input, Modal, Skeleton, Space, message } from "antd";
import {AliensNFTs, s0uImageFallback, s0uNFTs} from "utils/constant";
import { convertNFTImage } from "utils/web3.utils";
import storeHelper from "utils/storeHelper";

const { getAddress } = ethersUtils;
const { getLocalObject } = storeHelper;

const AvatarModal = props => {
  const {
    open,
    address,
    isLoading,
    nftBalance = [],
    userInfo,
    onSelectAvatar = () => { },
    onCancel = () => { },
    setUserData = () => { }
  } = props;

  const { discordname } = userInfo?.attributes || {};

  const localStoreToken = getLocalObject(`${address}`);

  const [discordName, setDiscordName] = useState(discordname);
  const [selectedToken, setSelectedToken] = useState({
    ...localStoreToken,
    token_id: `${localStoreToken?.token_id}`
  });

  const updateUserNickName = async () => {
    const response = await setUserData({
      discordname: discordName
    });
    if (response?.id) {
      message.open({
        type: 'success',
        content: `Update success!!!`,
      });
    }
  }

  return (
    <Modal
      title="Select Your Player"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={1000}
    >
      {
        nftBalance.length > 0
          ? <React.Fragment>
            <Space.Compact block>
              <Input
                defaultValue={discordName}
                style={{ height: 48, fontSize: 16 }}
                placeholder="input nick name"
                onChange={_event => setDiscordName(() => _event.target.value)}
              />
              <Button
                size="large"
                className="mainButton"
                onClick={updateUserNickName}
              >
                {'Update'}
              </Button>
            </Space.Compact>
            <div style={{ marginTop: 16 }} className="nftBoxGrid">
              <Skeleton loading={isLoading}>
                {nftBalance?.map((nft) => {
                  return (
                    <div
                      className={classNames({
                        ["nftBox"]: true,
                        ["selectedNFTStake"]: selectedToken?.token_id === nft.token_id && selectedToken?.token_address === nft.token_address
                      })}
                      key={nft.token_id}
                      onClick={() => setSelectedToken({ token_address: nft.token_address, token_id: `${nft.token_id}` })}
                    >
                      {
                        s0uNFTs[getAddress(nft.token_address)] === s0uNFTs.HUMANMUSIC
                          ? <video style={{ height: "100%", width: "auto", maxWidth: '100%' }} autoPlay muted controls={false}>
                            <source src={convertNFTImage(nft.token_address, nft.token_id, '') || "error"} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          : <Image
                            preview={false}
                            src={convertNFTImage(nft.token_address, nft.token_id, nft.metadata?.image) || "error"}
                            fallback={s0uImageFallback[nft.token_address]}
                            alt={nft?.metadata?.name || nft?.name}
                          />
                      }
                      <h4>{nft?.metadata?.name || nft?.name || `${AliensNFTs[nft.tokenAddress] ? '' : 'S0U - '}${s0uNFTs[nft.token_address]} #${nft.token_id}`}</h4>
                    </div>
                  )
                })}
              </Skeleton >
            </div >
            <div className={`${"footerControls"}`}>
              <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading || !selectedToken,
                })}
                disabled={isLoading || !selectedToken}
                onClick={() => onSelectAvatar(selectedToken)}
              >
                OK
              </Button>
            </div>
          </React.Fragment>
          : <div style={{ color: "#ffe000" }}>
            {"You must own or staking Singularity 0 Universe NFTs to claim chips"}
          </div>
      }

    </Modal>
  );
}

export default AvatarModal;
