import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { MoralisProvider } from "react-moralis";

import App from "./App";
import "./index.css";

const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER;
const APP_ID = process.env.REACT_APP_MORALIS_APP_ID;


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
    <Router basename={`${process.env.REACT_APP_SUBDIRECTORY}`}>
      <App />
    </Router>
  </MoralisProvider>
);
