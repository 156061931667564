import axios from "axios";
import { getAddress } from "ethers/lib/utils";
import { useEffect, useState } from "react";

export const useDiscordData = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const fileName = "s0u-discord.json";

  const formatKeysToAddress = _obj => {
    if (typeof _obj === "string") {
      return;
    }
    const newObj = {};
    Object.keys(_obj).forEach(key => {
      const newKey = getAddress(key);
      newObj[newKey] = _obj[key];
    });
    return newObj;
  };

  const fetchUploadFile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/uploads/${fileName}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.data) {
        setData(formatKeysToAddress(response.data));
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log("fetchUploadFile error: ", error);
      setIsLoading(false);
      return null;
    }
  };

  const uploadFile = async _file => {
    try {
      if (_file) {
        let response;
        const formData = new FormData();
        formData.append("file", _file, fileName);
        if (data) {
          response = await axios.put(
            `${process.env.REACT_APP_SERVER}/update/${fileName}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_SERVER}/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        fetchUploadFile();
        return response;
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUploadFile();
  }, []);

  return { data, fetchUploadFile, uploadFile, isLoading };
};
