import { Table } from "antd";
import { getAddress } from "ethers/lib/utils";
import Moralis from "moralis-v1";
import React, { useEffect, useState } from "react";
import {
  AliensNFTs,
  MUTANT_ALIENS_GENESISAddress,
  MUTANT_ALIENSAddress,
  s0uNFTs,
  YGY_MUTANT_ALIENSAddress
} from "utils/constant";

const CollectionLeaderBoard = () => {

  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState([]);

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'Collection',
      dataIndex: 'objectId',
      key: 'objectId',
      render: (_, _row) => {
        const { objectId } = _row;
        return (
          <span>
            {s0uNFTs[getAddress(objectId)] || ""}
          </span>
        )
      },
    },
    {
      title: 'Coins',
      dataIndex: 'totalChip',
      key: 'totalChip',
    },
  ];

  const getCollectionRanking = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const response = await Moralis.Cloud.run("getCollectionLeaderboard", {});
    let collections = response.filter(({ objectId }) => !!s0uNFTs[getAddress(objectId)])
    if (collections) {
      const mapCon = new Map();
      collections.forEach(({ objectId, totalChip }) => {
        if (mapCon.has(getAddress(objectId))) {
          mapCon.set(getAddress(objectId), mapCon.get(getAddress(objectId)) + totalChip)
        } else {
          mapCon.set(getAddress(objectId), totalChip)
        }
      })
      if (!mapCon.has(MUTANT_ALIENS_GENESISAddress)) {
        mapCon.set(MUTANT_ALIENS_GENESISAddress, 0)
      }
      if (!mapCon.has(MUTANT_ALIENSAddress)) {
        mapCon.set(MUTANT_ALIENSAddress, 0)
      }
      if (!mapCon.has(YGY_MUTANT_ALIENSAddress)) {
        mapCon.set(YGY_MUTANT_ALIENSAddress, 0)
      }

      collections = Array.from(mapCon.keys()).map(value => ({objectId: value, totalChip: mapCon.get(value)})).sort((a, b) =>  b.totalChip - a.totalChip)
    }

    setCollections(collections);
    setLoading(false);
  }

  useEffect(() => {
    getCollectionRanking();
  }, []);

  return (
    <Table
      columns={columns}
      rowKey="objectId"
      scroll={{ y: 500 }}
      pagination={false}
      dataSource={collections.map((player, index) => ({
        ...player,
        ...player?.attributes,
        rank: index + 1
      }))}
    />
  );
}

export default CollectionLeaderBoard;
