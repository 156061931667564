import React, { useEffect } from "react";
import classNames from 'classnames';

import { Button, Col, Form, Input, Modal, Row, Space, Switch } from "antd";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const SettingModal = props => {
  const {
    open,
    isLoading,
    data,
    onSubmit = () => { },
    onCancel = () => { },
    handleClearChips = () => { },
    handleClearTables = () => { },
    handleLockPlayers = () => { },
    handleUnlockTopPlayers = () => { },
    handleUnLockPlayers = () => { },
  } = props;
  const onSubmitForm = _data => {
    onSubmit &&
      onSubmit({
        showLeaderboard: _data.showLeaderboard,
        showPassword: _data.showPassword,
        disabledPlayer: _data.disabledPlayer,
        top: Math.round(_data.top),
        id: data?.id,
      });
  };
  const initialValues = {
    showLeaderboard: true,
    showPassword: false,
    disabledPlayer: false,
    top: 200,
  }

  const [settingForm] = Form.useForm();

  const onCancelForm = () => {
    settingForm.resetFields();
    onCancel();
  };

  useEffect(() => {
    settingForm.resetFields();
  }, [open]);

  useEffect(() => {
    if (data && settingForm?._init) {
      setTimeout(() => {
        settingForm.setFieldsValue({
          showLeaderboard: data?.attributes?.showLeaderboard,
          showPassword: data?.attributes?.showPassword,
          disabledPlayer: data?.attributes?.disabledPlayer,
          top: data?.attributes?.top,
        });
      }, 100);
    }
  }, [data, settingForm, open]);

  return (
    <Modal
      title={`Setting`}
      open={open}
      onCancel={onCancelForm}
      footer={null}
      maskClosable={false}
    >
      <div className="formContent">
        <Form
          form={settingForm}
          name="settingForm"
          layout="vertical"
          initialValues={initialValues}
          style={{ width: "100%" }}
          onFinish={onSubmitForm}
          disabled={isLoading}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                label="Show Password"
                name="showPassword"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Show Leaderboard"
                name="showLeaderboard"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Limit daily claim"
                name="disabledPlayer"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Top Players"
                name="top"
              >
                <Input
                  type="tel"
                  step={1}
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: 'left', marginBottom: 16 }}>
            <Space align="start" direction="horizontal">
              <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading,
                })}
                disabled={isLoading}
                onClick={handleClearChips}
              >
                {'Clear Chips'}
              </Button>
              <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading,
                })}
                disabled={isLoading}
                onClick={handleClearTables}
              >
                {'Clear Tables'}
              </Button>
              <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading,
                })}
                disabled={isLoading}
                onClick={() => handleLockPlayers()}
              >
                {'Lock Players'}
              </Button>
              <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading,
                })}
                disabled={isLoading}
                onClick={() => handleUnlockTopPlayers(data?.attributes?.top)}
              >
                {'Unlock Top Players'}
              </Button>
              {/* <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading,
                })}
                disabled={isLoading}
                onClick={() => handleUnLockPlayers()}
              >
                {'Unlock All Players'}
              </Button> */}
            </Space>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Space align="end" direction="horizontal">
              <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading,
                })}
                disabled={isLoading}
                htmlType="submit"
              >
                {'Save'}
              </Button>
            </Space>
          </div>
        </Form>
      </div >
    </Modal >
  );
}

export default SettingModal;
