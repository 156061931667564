import { Drawer, Table, Tabs } from "antd";
import { getAddress } from "ethers/lib/utils";
import Moralis from "moralis-v1";
import React, { useEffect, useMemo, useState } from "react";
import { s0uNFTs } from "utils/constant";
import CollectionLeaderBoard from "./collectionLeaderBoard";
import { useSetting } from "hooks/useSetting";

const LeaderBoard = props => {
  const {
    open,
    discordData,
    s0uPlayer,
    showLeaderboard,
    onClose = () => { }
  } = props;

  const [total, setTotal] = useState(0);
  const [playerRank, setRank] = useState(0);

  const [loading, setLoading] = useState(false);

  const [players, setPlayers] = useState([]);

  const { settingData } = useSetting();
  const { top: limit = 200 } = settingData || {};

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'Player',
      dataIndex: 'tokenAddress',
      key: 'tokenAddress',
      render: (_, _row) => {
        const { tokenAddress, tokenId, walletAddress, user } = _row;
        const playerName =
          discordData && discordData[walletAddress] ||
          discordData && discordData[getAddress(walletAddress)] ||
          user?.discordname ||
          "unknown";
        const tokenName = s0uNFTs[getAddress(tokenAddress)] || "";
        return (
          <span>
            {`${playerName && showLeaderboard ? playerName + ' - ' : ''}${tokenName} #${tokenId}`}
          </span>
        )
      },
    },
    {
      title: 'Coins',
      dataIndex: 'chips',
      key: 'chips',
      render: (_, _row) => {
        const { chips } = _row;
        return (
          <span>
            {showLeaderboard ? chips : ''}
          </span>
        )
      },
    },
  ];

  const getTotalPlayers = async _data => {
    const Obj = Moralis.Object.extend('Player');
    const query = new Moralis.Query(Obj);

    const response = await query.greaterThan("chips", 0).count();
    setTotal(response);
  }

  const playerChips = s0uPlayer?.chips;

  const getCurrentRank = async _data => {
    const Obj = Moralis.Object.extend('Player');
    const query = new Moralis.Query(Obj);

    const response = await query.greaterThan("chips", playerChips).count();
    setRank(response + 1);
  }

  const getPlayerRanking = async () => {
    if (loading || !showLeaderboard || !settingData) {
      return;
    }
    setLoading(true);
    const response = await Moralis.Cloud.run("fetchTopUser", { limit: 200 });
    setPlayers(response);
    setLoading(false);
  }

  useEffect(() => {
    getPlayerRanking();
  }, [showLeaderboard, limit]);

  useEffect(() => {
    getTotalPlayers();
  }, [players]);

  useEffect(() => {
    getCurrentRank();
    getPlayerRanking();
  }, [playerChips]);

  const PlayerLeaderboard = <React.Fragment>
    {/* {
      showLeaderboard && playerChips > 0
        ? <div style={{
          textTransform: "uppercase",
          fontWeight: 700,
          color: "gold",
          marginBottom: 16,
        }}>
          Your rank: {playerRank}
        </div>
        : null
    } */}
    {
      showLeaderboard
        ? <Table
          columns={columns}
          rowKey="objectId"
          scroll={{ y: 500 }}
          pagination={false}
          dataSource={players.map((player, index) => ({
            ...player,
            ...player?.attributes,
            rank: index + 1
          }))}
        />
        : null
    }
    <div>{total} players is playing now</div>
  </React.Fragment>

  const tabs = [
    {
      key: '1',
      label: 'Players',
      children: PlayerLeaderboard,
    },
    {
      key: '2',
      label: 'Colection',
      children: <CollectionLeaderBoard />,
    },
  ];

  return (
    <Drawer
      title="Leader Board"
      placement={"right"}
      closable={true}
      onClose={onClose}
      open={open}
      size="large"
      rowKey="id"
    >
      <Tabs defaultActiveKey="1" items={tabs} />

    </Drawer>
  );
}

export default LeaderBoard;
