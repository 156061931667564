export const numToAbbreviation = number => {
  if (number < 1000) {
    return String(number);
  }
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixNum = Math.floor(Math.log(number) / Math.log(1000));
  return `${(number / Math.pow(1000, suffixNum)).toFixed(0)}${
    suffixes[suffixNum]
  }`;
};
