import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";

export const useBet = params => {
  const { playerId, tableId = "", rouletteId = "" } = params;

  const [playerBet, setPlayerBet] = useState();
  const {
    data: bets,
    error,
    isLoading,
    fetch: fetchPlayerBet,
  } = useMoralisQuery(
    "PlayerBet",
    query =>
      query
      .equalTo("tableId", tableId)
      .equalTo("rouletteId", rouletteId),
    [tableId, rouletteId],
    {
      live: true,
      onLiveEnter: (entity, all) => {
        return [...all, entity];
      },
      onLiveCreate: (entity, all) => {
        return [entity, ...all];
      },
      onLiveDelete: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveLeave: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveUpdate: (entity, all) => {
        const existed = all.find(e => e.id === entity.id);
        if (existed) {
          return all.map(e => (e.id === entity.id ? entity : e));
        } else {
          return [entity, ...all];
        }
      },
    }
  );

  const betsMapping = bets.map(item => {
    return {
      ...item,
      ...item.attributes,
    };
  });
  const tableBets = betsMapping.map(item => {
    const { bets, ...rest } = item;
    const updatedBets = bets.map(bet => {
      return {
        ...bet,
        ...rest,
      };
    });
    return updatedBets;
  });

  useEffect(() => {
    const updatedBet = betsMapping.find(item => item.playerId === playerId);
    if (updatedBet) {
      setPlayerBet(prev => {
        if (JSON.stringify(prev) !== JSON.stringify(updatedBet)) {
          return updatedBet;
        } else {
          return prev;
        }
      });
    } else {
      setPlayerBet(null);
    }
  }, [betsMapping]);

  return {
    playerBet,
    tableBets: tableBets.flat(),
    error,
    isLoading: isLoading,
    fetchPlayerBet,
  };
};
