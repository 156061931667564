import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';

import "./style.css";
import Roulette from "components/Roulette";
import AuthLayout from "components/layout/authLayout";
import Tournament from "components/Tournament";
// import RouletteTable from "components/RouletteTable";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" exact element={<AuthLayout />}>
            <Route index element={<Roulette />} />
            <Route path="table/:id" element={<Roulette />} />
            <Route path="tournament" element={<Tournament />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </React.Suspense>
    </QueryClientProvider>
  );
};

export default App;
