import React from "react";
import classNames from 'classnames';

import 'assets/css/roulette.css';

const bbtopBlocks = ['1 to 18', '19 to 36'];
const bo3Blocks = ['1 to 12', '13 to 24', '25 to 36'];
const otoBlocks = ['EVEN', 'RED', 'BLACK', 'ODD'];

const numberBlocks = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, '2 to 1', 2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, '2 to 1', 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, '2 to 1'];
const redBlocks = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
const insideOdd = 35;
const numberOutside = {
  '12': '3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36',
  '25': '2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35',
  '38': '1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34'
};

const NumberBoard = ({
  betData = [],
  guessData = [],

  setBet = () => { },
  removeBet = () => { },
  renderChip = () => { },
}) => {
  const activeBetMap = betData.filter(b => b.amt !== 0).reduce((map, bet) => {
    map[`${bet.numbers}-${bet.type}`] = bet;
    return map;
  }, {});

  const onSelectNumber = (_number, _index, _objType) => {
    if (_number !== '2 to 1') {
      setBet('' + _number + '', _objType, insideOdd);
    } else {
      const num = numberOutside[`${_index}`];
      setBet(num, 'outside_column', 2);
    }
  };
  const onRemoveNumber = (_number, _index) => {
    if (_number !== '2 to 1') {
      removeBet('' + _number + '', _objType, insideOdd);
    } else {
      const num = numberOutside[`${_index}`];
      removeBet(num, 'outside_column', 2);
    }
  };

  const displayBetZero = betData.find(b => b.numbers === '0' && b.type === 'zero' && b.amt !== 0);
  const guessBetsZero = guessData.filter(b => b.numbers === '0' && b.type === 'zero' && b.amt !== 0);

  return (
    <React.Fragment>
      <div className="bbtop">
        {
          bbtopBlocks.map((bbtop, index) => {
            const num = (index === 0) ? '1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18' : '19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36';
            const objType = (index === 0) ? 'outside_low' : 'outside_high';
            const odd = 1;

            const displayBet = activeBetMap[`${num}-${objType}`];
            const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
            return <div
              key={`bbtoptwo${index}`}
              className="bbtoptwo"
              onClick={() => setBet(num, objType, odd)}
              onContextMenu={e => {
                e.preventDefault();
                removeBet(num, objType, odd)
              }}
            >
              {bbtop}
              {renderChip({ displayBet, guessBets })}
            </div>;
          })
        }
      </div>
      <div className="number_board">
        <div
          className="number_0"
          onClick={() => setBet('0', 'zero', insideOdd)}
          onContextMenu={e => {
            e.preventDefault();
            removeBet('0', 'zero', insideOdd);
          }}
        >
          <div className="nbn">{0}</div>
          {renderChip({ displayBet: displayBetZero, guessBets: guessBetsZero })}
        </div>
        {
          numberBlocks.map((number, index) => {
            const numStr = `${number}`;
            const num = numStr === '2 to 1' ? numberOutside[`${index}`] : numStr;
            const objType = numStr === '2 to 1' ? 'outside_column' : 'inside_whole';
            // const _odd = numStr === '2 to 1' ? 2 : insideOdd;

            const displayBet = activeBetMap[`${num}-${objType}`];
            const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
            return <div
              key={`block-${num}-${index}`}
              className={classNames({
                "redNum": redBlocks.includes(number),
                "blackNum": !redBlocks.includes(number) && num !== '2 to 1',
                "tt1_block": number === '2 to 1',
                "number_block": number !== '2 to 1',
              })}
              onClick={() => onSelectNumber(numStr, index, objType)}
              onContextMenu={e => {
                e.preventDefault();
                onRemoveNumber(num, index, objType);
              }}
            >
              <div className="nbn">{numStr === '2 to 1' ? numStr : num}</div>
              {/* {renderChip(
                num === '2 to 1' ? numberOutside[`${index}`] : num,
                num === '2 to 1' ? 'outside_column' : objType,
                num === '2 to 1' ? 2 : insideOdd
              )} */}
              {renderChip({ displayBet, guessBets })}
            </div>
          })
        }
      </div>
      <div className="bo3_board">
        {
          bo3Blocks.map((bo3Board, index) => {
            const num = (index === 0) ? '1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12' : ((index === 1) ? '13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24' : '25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36');
            const objType = 'outside_dozen'
            const odd = 2;

            const displayBet = activeBetMap[`${num}-${objType}`];
            const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
            return <div
              key={`bo3_block_${index}`}
              className="bo3_block"
              onClick={() => setBet(num, objType, odd)}
              onContextMenu={e => {
                e.preventDefault();
                removeBet(num, objType, odd)
              }}
            >
              {bo3Board}
              {renderChip({ displayBet, guessBets })}
            </div>;
          })
        }
      </div>
      <div className="oto_board">
        {
          otoBlocks.map((otoBoard, index) => {
            const num = (index === 0) ? '2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36' : ((index === 1) ? '1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36' : ((index === 2) ? '2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35' : '1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35'));
            const objType = 'outside_oerb'
            const odd = 1;

            const colourClass = (otoBoard === 'RED') ? ' redNum' : ((otoBoard === 'BLACK') ? ' blackNum' : '');
            const displayBet = activeBetMap[`${num}-${objType}`];
            const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
            return <div
              key={`oto_block_${index}`}
              className={`oto_block${colourClass}`}
              onClick={() => setBet(num, objType, odd)}
              onContextMenu={e => {
                e.preventDefault();
                removeBet(num, objType, odd)
              }}
            >
              {otoBoard}
              {renderChip({ displayBet, guessBets })}
            </div>;
          })
        }
      </div>
    </React.Fragment>
  );
}

export default NumberBoard;
