import React, { useEffect } from "react";
import classNames from 'classnames';

import { Button, Col, DatePicker, Form, Input, Modal, Row, Space, Switch } from "antd";
import * as Yup from "yup";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FORMAT } from "utils/constant";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from "moment";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const TournamentModal = props => {
  const {
    open,
    isLoading,
    data,
    onSubmit = () => { },
    onCancel = () => { }
  } = props;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name cannot be null")
      .max(240, "Max length is 240 charaters"),
    startDate: Yup.date()
      .required("Start date cannot be null"),
    // .min(new Date(), "Start date must bigger than today"),
    endDate: Yup.date()
      .required("End date cannot be null"),
      // .min(new Date(), "Start date must bigger than today"),
    reward: Yup.number()
      .required("Reward cannot be null")
      .min(1, "Reward must bigger than 0"),
    spinDuration: Yup.number()
      .required("Reward cannot be null")
      .min(1, "Reward must bigger than 0"),
    numberOfTables: Yup.number()
      .required("Reward cannot be null")
      .min(1, "Reward must bigger than 0"),
    maxPlayerPerTable: Yup.number()
      .required("Reward cannot be null")
      .min(1, "Reward must bigger than 0"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const onSubmitForm = _data => {
    onSubmit &&
      onSubmit({
        startTime: moment(_data.startDate.format(FORMAT.DATE)).valueOf(),
        endTime: moment(_data.endDate.format(FORMAT.DATE)).valueOf(),
        reward: parseInt(_data.reward, 10),
        name: _data.name,
        id: data?.id,
        showLeaderboard: _data.showLeaderboard,
        showPassword: _data.showPassword,
        spinDuration: parseInt(_data.spinDuration, 10),
        numberOfTables: parseInt(_data.numberOfTables, 10),
        maxPlayerPerTable: parseInt(_data.maxPlayerPerTable, 10)
      });
  };
  const initialValues = {
    name: "",
    startDate: dayjs().add(-1, 'd').minute(0),
    endDate: dayjs().add(1, 'd').minute(0),
    reward: 100,
    showLeaderboard: true,
    showPassword: true,
    spinDuration: 1,
    numberOfTables: 100,
    maxPlayerPerTable: 10
  }

  const [tournamentForm] = Form.useForm();

  const onCancelForm = () => {
    tournamentForm.resetFields();
    onCancel();
  };

  useEffect(() => {
    tournamentForm.resetFields();
  }, [open]);

  useEffect(() => {
    if (data && tournamentForm?._init) {
      setTimeout(() => {
        tournamentForm.setFieldsValue({
          name: data?.attributes?.name,
          startDate: dayjs(data?.attributes?.startTime),
          endDate: dayjs(data?.attributes?.endTime),
          reward: data?.attributes?.reward,
          showLeaderboard: data?.attributes?.showLeaderboard,
          showPassword: data?.attributes?.showPassword,
          spinDuration: data?.attributes?.spinDuration,
          numberOfTables: data?.attributes?.numberOfTables,
          maxPlayerPerTable: data?.attributes?.maxPlayerPerTable
        });
      }, 100);
    }
  }, [data, tournamentForm]);

  return (
    <Modal
      title={`Tournament ${data?.attributes?.name}`}
      open={open}
      onCancel={onCancelForm}
      footer={null}
      maskClosable={false}
    >
      <div className="formContent">
        <Form
          form={tournamentForm}
          name="tournamentForm"
          layout="vertical"
          initialValues={initialValues}
          style={{ width: "100%" }}
          onFinish={onSubmitForm}
          disabled={isLoading}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[yupSync]}
          >
            <Input
              autoComplete="off"
              type="text"
            />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[yupSync]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="MM-DD-YYYY HH:mm"
              showTime={true}
              showNow={false}
              disabledDate={current => current && current < dayjs().add(-1, 'd').endOf('day')}
              minuteStep={5}
              changeOnBlur={true}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            rules={[yupSync]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="MM-DD-YYYY HH:mm"
              showTime={true}
              showNow={false}
              disabledDate={current => current && current < dayjs(tournamentForm.getFieldValue('startDate').add(-1, 'd').endOf('day'))}
              minuteStep={5}
              changeOnBlur={true}
            />
          </Form.Item>
          <Form.Item
            label="Spin Duration(minutes)"
            name="spinDuration"
            rules={[yupSync]}
          >
            <Input
              type="tel"
              step={1}
            />
          </Form.Item>
          <Form.Item
            label="Max Player Per Table"
            name="maxPlayerPerTable"
            rules={[yupSync]}
          >
            <Input
              type="tel"
            />
          </Form.Item>
          <Form.Item
            label="Number Of Tables"
            name="numberOfTables"
            rules={[yupSync]}
          >
            <Input
              type="tel"
            />
          </Form.Item>

          <div style={{ textAlign: 'right' }}>
            <Space align="end" direction="horizontal">
              <Button
                size="small"
                className={classNames({
                  ["mainButton"]: true,
                  ["disabled"]: isLoading,
                })}
                disabled={isLoading}
                htmlType="submit"
              >
                {data ? 'Update' : 'Create'}
              </Button>
            </Space>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default TournamentModal;
