import { getAddress } from "ethers/lib/utils";
import { useMemo } from "react";
import { useMoralisQuery } from "react-moralis";

export const useTable = props => {
  const { tableId, discordData } = props;

  const {
    fetch: fetchTable,
    data = [],
    error,
    isLoading,
  } = useMoralisQuery(
    "Table",
    query => query.equalTo("objectId", tableId),
    [tableId],
    {
      live: true,
      onLiveUpdate: (entity, all) => {
        return all.map(e => (e.id === entity.id ? entity : e));
      },
    }
  );

  const rouletteTable = useMemo(() => {
    if (data.length > 0) {
      const currentTable = data[0];
      return {
        ...currentTable,
        ...currentTable.attributes,
      };
    } else {
      return null;
    }
  }, [data]);

  const playerIds = useMemo(() => {
    return rouletteTable?.playerIds;
  }, [rouletteTable]);

  const {
    data: players = [],
    isLoading: isLoadingPlayers,
    fetch: fetchTablePlayers,
  } = useMoralisQuery(
    "Player",
    query => query.equalTo("objectId", { $in: playerIds }),
    [playerIds]
  );

  const tablePlayers = useMemo(() => {
    return players.map(player => {
      const playerName =
        (discordData && discordData[player?.attributes?.walletAddress]) ||
        (discordData &&
          discordData[getAddress(player?.attributes?.walletAddress)]) ||
        null;
      return {
        ...player,
        ...player.attributes,
        name: playerName,
      };
    });
  }, [players, discordData]);

  return {
    rouletteTable,
    tablePlayers,
    error,
    isLoading: isLoading || isLoadingPlayers,
    fetchTable,
    isLoadingPlayers,
    fetchTablePlayers,
  };
};
