import moment from "moment";
import Moralis from "moralis-v1";
import { useEffect, useState } from "react";
import { useMoralisQuery, useMoralisSubscription } from "react-moralis";

export const useRoulette = () => {
  const [currentDate, setCurrentDate] = useState(moment().utc().valueOf());

  const [loading, setLoading] = useState(false);
  const [activeRoulette, setActiveRoulette] = useState();

  const {
    data: roulettes,
    error,
    isLoading,
    fetch: fetchRoulette,
  } = useMoralisQuery(
    "Roulette",
    query =>
      query
        .descending("_created_at")
        .greaterThanOrEqualTo("endTime", currentDate),
    [currentDate],
    {
      live: true,
      onLiveEnter: (entity, all) => {
        return [...all, entity];
      },
      onLiveCreate: (entity, all) => {
        // setCurrentDate(moment().utc().valueOf());
        return [entity, ...all];
      },
      onLiveDelete: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveLeave: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveUpdate: (entity, all) => {
        // setCurrentDate(moment().utc().valueOf());
        const existed = all.find(e => e.id === entity.id);
        if (existed) {
          return all.map(e => (e.id === entity.id ? entity : e));
        } else {
          return [entity, ...all];
        }
      },
    }
  );

  const getRouletteDetail = async _id => {
    setLoading(true);
    const rouletteObject = Moralis.Object.extend("Roulette");
    const query = new Moralis.Query(rouletteObject);
    const result = await query.get(_id);
    setLoading(false);
    if (result) {
      setActiveRoulette({
        ...result,
        ...result.attributes,
      });
      return {
        ...result,
        ...result.attributes,
      };
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (roulettes.length > 0) {
      const validRoulette = roulettes[0];
      setActiveRoulette({
        ...validRoulette,
        ...validRoulette.attributes,
      });
    }
  }, [roulettes]);

  return {
    activeRoulette,
    error,
    isLoading: isLoading || loading,
    fetchRoulette,
    getRouletteDetail,
  };
};
