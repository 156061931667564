import React from "react";
import classNames from 'classnames';

import 'assets/css/roulette.css';

const WinningLines = ({
  betData = [],
  guessData = [],

  setBet = () => { },
  removeBet = () => { },
  renderChip = () => { },
}) => {
  const winninglines = [...Array(12).keys()];
  const wlttb = [...Array(3).keys()].map(num => num + 1);
  const wlrtl = [...Array(11).keys()].map(num => num + 1);
  const wlcb = [...Array(2).keys()].map(num => num + 1);

  return (
    <div className="winning_lines">
      <div
        id="wlttb_top"
        className="wlttb"
      >
        {
          wlrtl.map(number => {
            const numA = (1 + (3 * number));
            const numB = (2 + (3 * number));
            const numC = (3 + (3 * number));
            const numD = (4 + (3 * number));
            const numE = (5 + (3 * number));
            const numF = (6 + (3 * number));
            const num = numA + ', ' + numB + ', ' + numC + ', ' + numD + ', ' + numE + ', ' + numF;

            const objType = 'double_street';
            const odd = 5;
            const displayBet = betData.find(b => b.numbers === num && b.type === objType && b.amt !== 0);
            const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
            return <div
              key={`wlttb_top_${number}`}
              className={classNames({
                "ttbbetblock": true,
              })}
              onClick={() => setBet(num, objType, odd)}
              onContextMenu={e => {
                e.preventDefault();
                removeBet(num, objType, odd);
              }}
            >
              {/* {renderChip(num, objType, odd)} */}
              {renderChip({ displayBet, guessBets })}
            </div>
          })
        }
      </div>
      {
        wlttb.map(number => {
          return <div
            id={`wlttb_${number}`}
            key={`wlttb_${number}`}
            className="wlttb"
          >
            {
              winninglines.map(item => {
                let num, numA, numB, numC;
                if (number === 1 || number === 2) {
                  numA = ((2 - (number - 1)) + (3 * item));
                  numB = ((3 - (number - 1)) + (3 * item));
                  num = numA + ', ' + numB;
                }
                else {
                  numA = (1 + (3 * item));
                  numB = (2 + (3 * item));
                  numC = (3 + (3 * item));
                  num = numA + ', ' + numB + ', ' + numC;
                }
                const objType = (number === 3) ? 'street' : 'split';
                const odd = (number === 3) ? 11 : 17;

                const displayBet = betData.find(b => b.numbers === num && b.type === objType && b.amt !== 0);
                const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
                return <div
                  key={`ttbbetblock-${item}`}
                  className={classNames({
                    "ttbbetblock": true,
                  })}
                  onClick={() => setBet(num, objType, odd)}
                  onContextMenu={e => {
                    e.preventDefault();
                    removeBet(num, objType, odd)
                  }}
                >
                  {/* {renderChip(num, objType, odd)} */}
                  {renderChip({ displayBet, guessBets })}
                </div>
              })
            }
          </div>
        })
      }
      {
        wlrtl.map(number => {
          return <div
            id={`wlrtl_${number}`}
            key={`wlrtl_${number}`}
            className="wlrtl"
          >
            {
              wlttb.map(item => {
                let num, numA, numB;
                numA = (3 + (3 * (number - 1))) - (item - 1);
                numB = (6 + (3 * (number - 1))) - (item - 1);
                num = numA + ', ' + numB;

                const objType = 'split';
                const odd = 17;

                const displayBet = betData.find(b => b.numbers === num && b.type === objType && b.amt !== 0);
                const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
                return <div
                  key={`rtlbb-${item}`}
                  className={classNames({
                    [`rtlbb${item}`]: true,
                  })}
                  onClick={() => setBet(num, objType, odd)}
                  onContextMenu={e => {
                    e.preventDefault();
                    removeBet(num, objType, odd)
                  }}
                >
                  {/* {renderChip(num, objType, odd)} */}
                  {renderChip({ displayBet, guessBets })}
                </div>
              })
            }
          </div>
        })
      }
      {
        wlcb.map(number => {
          return <div
            id={`wlcb_${number}`}
            key={`wlcb_${number}`}
            className="wlcb"
          >
            {
              wlrtl.map(item => {
                const count = (number === 1) ? item : item + 11;
                const numA = '2';
                const numB = '3';
                const numC = '5';
                const numD = '6';
                const num = (count >= 1 && count < 12)
                  ? (parseInt(numA) + ((count - 1) * 3)) + ', ' + (parseInt(numB) + ((count - 1) * 3)) + ', ' + (parseInt(numC) + ((count - 1) * 3)) + ', ' + (parseInt(numD) + ((count - 1) * 3))
                  : ((parseInt(numA) - 1) + ((count - 12) * 3)) + ', ' + ((parseInt(numB) - 1) + ((count - 12) * 3)) + ', ' + ((parseInt(numC) - 1) + ((count - 12) * 3)) + ', ' + ((parseInt(numD) - 1) + ((count - 12) * 3));

                const objType = 'corner_bet';
                const odd = 8;

                const displayBet = betData.find(b => b.numbers === num && b.type === objType && b.amt !== 0);
                const guessBets = guessData.filter(item => item.numbers === num && item.type === objType && item.amt !== 0);
                return <div
                  id={`cbbb_${item}`}
                  key={`cbbb_${item}`}
                  className={classNames({
                    "cbbb": true,
                  })}
                  onClick={() => setBet(num, objType, odd)}
                  onContextMenu={e => {
                    e.preventDefault();
                    removeBet(num, objType, odd)
                  }}
                >
                  {/* {renderChip(num, objType, odd)} */}
                  {renderChip({ displayBet, guessBets })}
                </div>
              })
            }
          </div>
        })
      }
    </div>
  );
}

export default WinningLines;
