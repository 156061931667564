import React, { useEffect, useMemo, useState } from "react";

import 'assets/css/roulette.css';
import { Button, Popconfirm, Space, Table, Tag, Upload, message } from "antd";
import classNames from "classnames";
import TournamentModal from "./modal/tournamentModal";
import { useMoralis, useMoralisQuery, useNewMoralisObject } from "react-moralis";
import dayjs from "dayjs";
import { FORMAT, s0uNFTs } from "utils/constant";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { HomeOutlined, UploadOutlined, DownloadOutlined, SettingOutlined } from "@ant-design/icons";
import { useDiscordData } from "hooks/useDiscordData";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { getAddress } from "ethers/lib/utils";
import { useWhitelist } from "hooks/useWhitelist";
import SettingModal from "./modal/settingModal";


dayjs.extend(customParseFormat);
dayjs.extend(utc);

const Tournament = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [showModal, setShowModal] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const headers = [
    { label: 'Rank', key: 'rank' },
    { label: 'Player', key: 'player' },
    { label: 'Coins', key: 'coins' },
  ];

  const { Moralis } = useMoralis();
  const { isSaving, error: errorCreate, save: saveTournament } = useNewMoralisObject('Tournament');

  const { fetch: fetchTournaments, data: tournamentList, error, isLoading: isLoadingTournament } = useMoralisQuery(
    "Tournament",
    query =>
      query.limit(100),
    [],
  );
  const [players, setPlayers] = useState([]);
  const [isLoadingExport, setLoadingExport] = useState(false);

  const getTournaments = async () => {
    if (isLoadingExport || !players) {
      return;
    }
    setLoadingExport(true);
    const response = await Moralis.Cloud.run("getPlayers", {});
    const player = response.map((row, index) => {
      const { tokenAddress, tokenId, walletAddress, user } = row;
      const playerName =
        discordData && discordData[walletAddress] ||
        discordData && discordData[getAddress(walletAddress)] ||
        user?.discordname ||
        "unknown";
      const tokenName = s0uNFTs[getAddress(tokenAddress)] || "";
      return {
        rank: index + 1,
        player: `${playerName ? playerName + ' - ' : ''}${tokenName} #${tokenId}`,
        coins: row.chips
      }
    })
    setPlayers(player);
    setTimeout(() => {
      document.querySelector('.csv-link').click();
    }, 1)
    setLoadingExport(false);
  }

  const { data: tournamentDetail, isLoading: isLoadingDetail } = useMoralisQuery(
    "Tournament",
    query =>
      query.equalTo("objectId", selectedTournament?.objectId),
    [selectedTournament],
  );

  const { data: settingData, isLoading: isLoadingSetting, fetch: fetchSetting } = useMoralisQuery(
    "Setting",
    query =>
      query
        .descending("_created_at")
        .limit(1),
    [],
  );

  const loading = useMemo(() => {
    return isLoading || isLoadingTournament || isLoadingDetail || isLoadingExport
  }, [isLoading, isLoadingTournament, isLoadingDetail, isLoadingExport])

  const { uploadFile, data: discordData } = useDiscordData();
  const { uploadFile: uploadWhitelist } = useWhitelist();

  const validateTournament = async _data => {
    const { startTime, endTime, id = '' } = _data;
    setIsLoading(true);
    const queryStartTime = new Moralis.Query('Tournament')
      .lessThanOrEqualTo('startTime', startTime)
      .greaterThanOrEqualTo('endTime', startTime)
      .notEqualTo('objectId', id);
    const responseQuery1 = await queryStartTime.find({
      useMasterKey: true
    });
    if (responseQuery1.length > 0) {
      messageApi.open({
        type: 'error',
        content: 'Tournament already exist',
      });
      return false;
    }
    const queryEndTime = await new Moralis.Query('Tournament')
      .lessThanOrEqualTo('startTime', endTime)
      .greaterThanOrEqualTo('endTime', endTime)
      .notEqualTo('objectId', id)
      .find({
        useMasterKey: true
      });
    if (queryEndTime.length > 0) {
      messageApi.open({
        type: 'error',
        content: 'Tournament already exist',
      });
      return false;
    }

    return true;
  }

  const createTournament = async _data => {
    // const TournamentObj = Moralis.Object.extend('Tournament');
    setIsLoading(true);

    const isValid = await validateTournament(_data);
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    // const tournament = new TournamentObj();

    // await tournament.save(_data, {
    //   useMasterKey: true
    // });
    await Moralis.Cloud.run("createTournament", _data);
    setIsLoading(false);
    // fetchTournaments();
  }

  const updateTournament = async _data => {
    setIsLoading(true);
    const isValid = await validateTournament(_data);
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    await Moralis.Cloud.run("updateTournament", _data);
    setIsLoading(false);
  }

  //create const tournaments by map data with useMemo
  const tournaments = useMemo(() => {
    return tournamentList?.map(item => {
      return {
        ...item,
        ...item.attributes,
      }
    });
  }, [tournamentList]);

  const activeTournament = useMemo(() => {
    const currentDate = moment().utc().valueOf();
    return tournaments.find(item => item.startTime <= currentDate && item.endTime >= currentDate);
  }, [tournaments]);

  const handleDelete = async _id => {
    setIsLoading(true);
    const TournamentObj = Moralis.Object.extend('Tournament');
    const query = new Moralis.Query(TournamentObj);

    const tournament = await query.get(_id);
    await tournament.destroy();
    setIsLoading(false);
    fetchTournaments();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (date) => <span>{dayjs(date).format(FORMAT.DATE)}</span>,
    },
    {
      title: 'End Date',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (date) => <span>{dayjs(date).format(FORMAT.DATE)}</span>,
    },
    {
      title: 'Spin Duration',
      dataIndex: 'spinDuration',
      key: 'spinDuration',
    },
    {
      title: 'Number Of Tables',
      dataIndex: 'numberOfTables',
      key: 'numberOfTables',
    },
    {
      title: 'Max Player Per Table',
      dataIndex: 'maxPlayerPerTable',
      key: 'maxPlayerPerTable',
    },
    {
      title: 'Status',
      key: 'id',
      render: (_, record) => {
        return (
          <Space size="middle">
            {
              record?.id === activeTournament?.id
                ? <Tag color={'green'}>
                  {'Active'}
                </Tag>
                : <Tag color={'volcano'}>
                  {'Inactive'}
                </Tag>
            }
          </Space>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="middle">
            <a style={{ cursor: "pointer", fontWeight: 700 }} onClick={() => {
              setShowModal(true);
              setSelectedTournament({ objectId: record.id });
            }}>Detail</a>
            {
              record?.id !== activeTournament?.id
                ? <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                  <a style={{ cursor: "pointer", color: "#d4380d", fontWeight: 700 }}>Delete</a>
                </Popconfirm>
                : null
            }
          </Space>
        )
      },
    },
  ];

  const onSubmit = async _data => {
    try {
      if (_data?.id) {
        await updateTournament(_data);
      } else {
        await createTournament(_data);
      }
      fetchTournaments();
      setShowModal(false);
      messageApi.open({
        type: 'success',
        content: _data?.id ? "Update Tournament success" : "Create Tournament success",
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: "Update failed!!!",
      });
    }
  }
  const onUpdateSetting = async _data => {
    try {
      setIsLoading(true);
      const SettingObj = Moralis.Object.extend('Setting');

      const query = new Moralis.Query(SettingObj);
      let existedSetting;
      if (_data.id) {
        existedSetting = await query.get(_data.id);
      }

      const setting = existedSetting ? existedSetting : new SettingObj();
      setting.set(_data);
      await setting.save();
      setIsLoading(false);
      fetchSetting();

      setShowSetting(false);
      messageApi.open({
        type: 'success',
        content: "Save setting success",
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: "Update failed!!!",
      });
    }
  }

  const onAddTournament = async () => {
    setSelectedTournament(null);
    setShowModal(true)
  }

  useEffect(() => {
    if (errorCreate) {
      messageApi.open({
        type: 'error',
        content: errorCreate.message,
      });
    }
  }, [isSaving, errorCreate]);

  const uploadProps = {
    accept: "application/json,.py",
    beforeUpload: async (_file) => {
      const response = await uploadFile(_file);
      if (response.status === 200 || response.status === 201) {
        messageApi.open({
          type: 'success',
          content: "Upload success",
        });
      }
      return false;
    },
  };

  const uploadWhitelistProps = {
    accept: "application/json,.py",
    beforeUpload: async (_file) => {
      const response = await uploadWhitelist(_file);
      if (response.status === 200 || response.status === 201) {
        messageApi.open({
          type: 'success',
          content: "Upload success",
        });
      }
      return false;
    },
  };

  const handleClearChips = async () => {
    try {
      await Moralis.Cloud.run("clearChips");
      messageApi.open({
        type: 'success',
        content: `Clear chips!!!`,
      });
    } catch (error) {
      console.log("Clear chips error: ", error);
      messageApi.open({
        type: 'error',
        content: `An error happen when clear chips`,
      });
    }
  }
  const handleClearTables = async () => {
    try {
      await Moralis.Cloud.run("clearTables");
      messageApi.open({
        type: 'success',
        content: `Clear tables!!!`,
      });
    } catch (error) {
      console.log("Clear tables error: ", error);
      messageApi.open({
        type: 'error',
        content: `An error happen when clear tables`,
      });
    }
  }
  const handleLockPlayers = async (limit) => {
    try {
      await Moralis.Cloud.run("lockPlayers", { limit });
      messageApi.open({
        type: 'success',
        content: `Lock players!!!`,
      });
    } catch (error) {
      console.log("Lock players error: ", error);
      messageApi.open({
        type: 'error',
        content: `An error happen when lock players`,
      });
    }
  }

  const handleUnLockPlayers = async (limit) => {
    try {
      await Moralis.Cloud.run("unlockPlayers", { limit });
      messageApi.open({
        type: 'success',
        content: `Lock players!!!`,
      });
    } catch (error) {
      console.log("Lock players error: ", error);
      messageApi.open({
        type: 'error',
        content: `An error happen when lock players`,
      });
    }
  }
  const handleUnlockTopPlayers = async (limit) => {
    try {
      console.log("Unlock top players: ", limit);
      // await Moralis.Cloud.run("unlockTopPlayers", { limit });
      await Moralis.Cloud.run("unlockTopUserPlayers", { limit });
      messageApi.open({
        type: 'success',
        content: `Unlock top players!!!`,
      });
    } catch (error) {
      console.log("Unlock top players error: ", error);
      messageApi.open({
        type: 'error',
        content: `An error happen when unlock top players`,
      });
    }
  }

  return (
    <div className="contentWrap">
      {contextHolder}
      <div className="wrapBtn">
        <span className="iconWrap" onClick={() => navigate('/')}><HomeOutlined /></span>
      </div>
      <div className="wrapBtn">
        <Button
          size="small"
          className={classNames({
            ["mainButton"]: true,
          })}
          onClick={onAddTournament}
          disabled={isLoading}
        >
          {'Create Tournament'}
        </Button>
        <Upload {...uploadProps}>
          <Button
            icon={<UploadOutlined />}
            size="small"
            className={classNames({
              ["mainButton"]: true,
            })}
            disabled={isLoading}
          >Upload Discord JSON</Button>
        </Upload>
        <Upload {...uploadWhitelistProps}>
          <Button
            icon={<UploadOutlined />}
            size="small"
            className={classNames({
              ["mainButton"]: true,
            })}
            disabled={isLoading}
          >Upload Whitelist JSON</Button>
        </Upload>
        <Button
          icon={<DownloadOutlined />}
          size="small"
          className={classNames({
            ["mainButton"]: true,
          })}
          onClick={getTournaments}
          disabled={loading}
        >
          Export Leaderboard
        </Button>
        <Button
          icon={<SettingOutlined />}
          size="small"
          className={classNames({
            ["mainButton"]: true,
          })}
          onClick={() => setShowSetting(true)}
        >
          Setting
        </Button>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={tournaments}
          rowKey="id"
          loading={loading}
        />
      </div>
      <TournamentModal
        onSubmit={onSubmit}
        onCancel={() => setShowModal(false)}
        isLoading={false}
        open={selectedTournament ? showModal && tournamentDetail[0] : showModal}
        data={selectedTournament ? tournamentDetail[0] : null}
      />
      <SettingModal
        onSubmit={onUpdateSetting}
        onCancel={() => setShowSetting(false)}
        isLoading={false}
        open={showSetting}
        data={settingData && settingData[0]}
        handleClearChips={handleClearChips}
        handleClearTables={handleClearTables}
        handleLockPlayers={handleLockPlayers}
        handleUnlockTopPlayers={handleUnlockTopPlayers}
        handleUnLockPlayers={handleUnLockPlayers}
      />
      <CSVLink data={players} headers={headers} filename={'leaderboard_' + new Date().getTime() + '.csv'} className="csv-link" style={{ display: 'none' }}>
      </CSVLink>
    </div>
  );
}

export default Tournament;
