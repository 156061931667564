import Moralis from "moralis-v1";
import { useEffect, useMemo, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { utils as ethersUtils } from "ethers";
import StoreHelper from "utils/storeHelper";
import { useWhitelist } from "./useWhitelist";
import {AliensNFTs} from "../utils/constant";

const { getAddress } = ethersUtils;
const { removeLocalItem } = StoreHelper;

export const usePlayer = props => {
  const {
    token_address: tokenAddress,
    wallet: walletAddress,
    nftBalance = [],
    address,
    tableId,
  } = props;
  const tokenId = `${props.token_id}`;

  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState();
  const [isValidNFT, setIsValidNFT] = useState(false);

  const { data: whitelistData } = useWhitelist();

  const {
    fetch: fetchPlayer,
    data: players = [],
    error,
    isLoading,
  } = useMoralisQuery(
    "Player",
    query =>
      query
        .equalTo("walletAddress", walletAddress)
        .equalTo("tokenAddress", tokenAddress)
        .equalTo("tokenId", tokenId),
    [(walletAddress, tokenAddress, tokenId)],
    {
      live: true,
      onLiveUpdate: (entity, all) => {
        return all.map(e => (e.id === entity.id ? entity : e));
      },
    }
  );

  const getPlayerDetail = async _id => {
    setLoading(true);
    const playerObject = Moralis.Object.extend("Player");
    const query = new Moralis.Query(playerObject);
    const result = await query.get(_id);
    setLoading(false);
    if (result) {
      setPlayer({
        ...result,
        ...result.attributes,
      });
      return {
        ...result,
        ...result.attributes,
      };
    } else {
      return null;
    }
  };

  const handleCreatePlayer = async () => {
    const playerObject = Moralis.Object.extend("Player");
    const query = new Moralis.Query(playerObject);
    const existed = await query
      .equalTo("walletAddress", walletAddress)
      .equalTo("tokenAddress", tokenAddress)
      .equalTo("tokenId", tokenId)
      .find();


    if (existed.length === 0 && isValidNFT && !loading) {
      let image = null
      if (AliensNFTs[getAddress(tokenAddress)]) {
        const userInfo = nftBalance.find(x => x.token_address === tokenAddress && x.token_id === tokenId)
        if (userInfo) {
          image = userInfo.metadata ? userInfo.metadata.image : null
        }
        if (!image) {
          image = 'https://images.relic758-nft.com/genesis/images/e7d4c77b7b1af931ef56d4e0ec5c0ac02e61e65b23e85c941e450dd67a212aa6.gif'
        }
      }
      const data = {
        walletAddress,
        tokenAddress,
        tokenId,
        image
      };
      setLoading(true);
      const response = await Moralis.Cloud.run("createPlayer", data);
      setLoading(false);
      fetchPlayer();
    } else if (existed.length > 0) {
      console.log(existed[0], 'existedPlayer')

      const existedPlayer = existed[0];
      setPlayer({
        ...existedPlayer,
        ...existedPlayer?.attributes,
      });
    } else {
      removeLocalItem(address);
    }

  };

  useEffect(() => {
    if (walletAddress && tokenAddress && tokenId && isValidNFT) {
      handleCreatePlayer();
    }
  }, [walletAddress, tokenAddress, tokenId, isValidNFT]);

  useEffect(() => {
    if (players.length > 0) {
      const currentPlayer = players[0];
      setPlayer({
        ...currentPlayer,
        ...currentPlayer.attributes,
      });
    }
  }, [players]);

  useEffect(() => {
    if (nftBalance.length > 0 && tokenAddress) {
      const ownedNFT = nftBalance.find(nft => {
        return (
          getAddress(nft.token_address) === getAddress(tokenAddress) &&
          nft.token_id === tokenId
        );
      });
      if (ownedNFT) {
        setIsValidNFT(() => true);
      } else {
        setIsValidNFT(() => false);
      }
    } else {
      setIsValidNFT(() => false);
    }
  }, [nftBalance]);

  const isGuess = useMemo(() => {
    const isIncluded =
      whitelistData.includes(walletAddress) ||
      whitelistData.includes(getAddress(walletAddress));
    return whitelistData ? !isIncluded : true;
  }, [whitelistData, walletAddress]);

  return {
    player: isValidNFT ? player : null,
    error,
    isLoading: isLoading || loading,
    fetchPlayer,
    getPlayerDetail,
    isGuess: isGuess,
  };
};
