import React, { useEffect, useRef } from "react";
import classNames from 'classnames';

import DealerImage from 'assets/images/dealer.png';

import 'assets/css/roulette.css';
import { Badge, Image } from "antd";
import { ROULETTE_STATUS, s0uImageFallback, s0uNFTs } from "utils/constant";
import { convertNFTImage } from "utils/web3.utils";
import { utils as ethersUtils } from "ethers";
import { useMedia } from "react-use";
import Countdown from "react-countdown";
import { useParams } from "react-router-dom";

const { getAddress } = ethersUtils;

const Wheel = props => {
  const {
    bankValue,
    currentBet,
    wager,
    chips,
    wheelRef,
    selectedChip,
    userAvatar,
    discordname,
    disabled = false,
    activeRoulette,
    numberOfNfts,

    onSelectChip = () => { },
    spin = () => { },
    startSpin = () => { },
    endSpin = () => { },
    setIsAvatarModalOpen = () => { }
  } = props;

  const below768 = useMedia('(max-width: 768px)');
  const { id: tableId } = useParams();
  const countdownRef = useRef();

  const wheelnumbers = [0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26];

  useEffect(() => {
    if (activeRoulette?.status === ROULETTE_STATUS.SUCCESS && activeRoulette?.winningSpins?.length > 0) {
      const currentTable = activeRoulette?.winningSpins.find(item => item.tableId === tableId);
      if (currentTable) {
        console.log("currentTable: ", currentTable?.winningSpin);
        endSpin(currentTable?.winningSpin);
      } else {
        console.log("not currentTable: ", activeRoulette);
        endSpin(Math.floor(Math.random() * 37));
      }
    } else if (activeRoulette?.status === ROULETTE_STATUS.PENDING) {
      console.log("pending currentTable: ", activeRoulette);
      countdownRef?.current?.start();
      endSpin();
    }
  }, [activeRoulette])

  // Renderer callback with condition
  const rendererCountdown = ({ completed, formatted }) => {
    const { minutes, seconds } = formatted;
    if (completed) {
      // Render a completed state
      return <span>Spinning</span>;
    } else {
      // Render a countdown
      return <span>Next Spin: {minutes}:{seconds}</span>;
    }
  };

  return (
    <div className="wheelContainer">
      {
        below768
          ? null
          : <div className="dealerWrap">
            <Image
              preview={false}
              src={DealerImage}
              className="dealerImage"
            />
          </div>
      }
      <div className="wheelWrap">
        <div className="countdown">
          {
            activeRoulette?.betTime
              ? <Countdown
                date={(new Date(activeRoulette?.betTime))}
                renderer={rendererCountdown}
                intervalDelay={0}
                precision={3}
                onComplete={startSpin}
                ref={countdownRef}
              />
              : <div>Waiting for next spin</div>
          }
        </div>
        <div ref={wheelRef} className="wheel">
          <div className="outerRim"></div>
          {
            wheelnumbers.map((item, index) => (
              <div key={`wheelnumber-${item}-${index}`} className="sect" id={`sect${index + 1}`}>
                <span className={item < 10 ? 'single' : 'double'}>{item}</span>
                <div className="block"></div>
              </div>
            ))
          }
          <div className="pocketsRim"></div>
          <div className="ballTrack">
            <div className="ball"></div>
          </div>
          <div className="pockets"></div>
          <div className="cone"></div>
          <div className="turret"></div>
          <div className="turretHandle">
            <div className="thendOne"></div>
            <div className="thendTwo"></div>
          </div>
        </div>
        <div className={classNames({
          ["chipDeck"]: true,
          ["disabled"]: disabled
        })}>
          {
            chips.map((chip, index) => {
              return <div
                key={`chip-${index}`}
                className={classNames({
                  "cdChip": true,
                  [chip.color]: true,
                  "cdChipActive": chip.value === selectedChip.value,
                })}
                onClick={() => onSelectChip(chip)}
              >
                <span className="cdChipSpan">
                  {chip.label}
                </span>
              </div>
            })
          }
          {
            wager > 0 && currentBet !== 0
              ? <div
                className="spinBtn"
                onClick={spin}
              >bet</div>
              : null
          }
        </div>
        <div className="bankContainer">
          <div className="bank">
            <span className="bankSpan">{bankValue.toLocaleString("en-GB")}</span>
          </div>
          <div className="bet">
            <span className="betSpan">{currentBet.toLocaleString("en-GB")}</span>
          </div>
        </div>
        {
          userAvatar?.tokenAddress && userAvatar?.tokenId
            ? <React.Fragment>
              {
                discordname
                  ? <div style={{
                    fontWeight: 700,
                    color: "gold",
                    textAlign: "center",
                    marginBottom: -26,
                  }}>
                    {discordname}
                  </div>
                  : null
              }
              <Badge color="gold" count={numberOfNfts} offset={[-30, 30]}>
                <div
                  className={classNames({
                    ["nftAvatar"]: true,
                  })}
                  onClick={() => setIsAvatarModalOpen(true)}
                >
                  {
                    s0uNFTs[getAddress(userAvatar.tokenAddress)] === s0uNFTs.HUMANMUSIC
                      ? <video style={{ height: "100%", width: "auto", maxWidth: '100%' }} controls={false} autoPlay muted>
                        <source src={convertNFTImage(userAvatar.tokenAddress, userAvatar.tokenId, '') || "error"} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      : <div className="nftAvatarWrap">
                        <Image
                          preview={false}
                          src={convertNFTImage(userAvatar.tokenAddress, userAvatar.tokenId, userAvatar.image) || "error"}
                          fallback={s0uImageFallback[userAvatar.tokenAddress]}
                          style={{ objectFit: "contain" }}
                        />
                        {/* <div className="nftAvatarCount">{numberOfNfts}</div> */}
                      </div>
                  }
                </div>
              </Badge>
            </React.Fragment>
            : null
        }
      </div>
    </div>
  );
}

export default Wheel;
