import { getAddress } from "ethers/lib/utils";

export const ANTHROAddress =
  getAddress(process.env.REACT_APP_ANTHRO_CONTRACT) ||
  process.env.REACT_APP_ANTHRO_CONTRACT;
export const HUMANAddress =
  getAddress(process.env.REACT_APP_HUMAN_CONTRACT) ||
  process.env.REACT_APP_HUMAN_CONTRACT;
export const HUMANMUSICAddress =
  getAddress(process.env.REACT_APP_HUMANMUSIC_CONTRACT) ||
  process.env.REACT_APP_HUMANMUSIC_CONTRACT;
export const ANKOKUAddress =
  getAddress(process.env.REACT_APP_ANKOKU_CONTRACT) ||
  process.env.REACT_APP_ANKOKU_CONTRACT;

export const MUTANT_ALIENS_GENESISAddress =
    getAddress(process.env.REACT_APP_MUTANT_ALIENS_GENESIS_CONTRACT) ||
    process.env.REACT_APP_MUTANT_ALIENS_GENESIS_CONTRACT;

export const MUTANT_ALIENSAddress =
    getAddress(process.env.REACT_APP_MUTANT_ALIENS_CONTRACT) ||
    process.env.REACT_APP_MUTANT_ALIENS_CONTRACT;

export const YGY_MUTANT_ALIENSAddress =
    getAddress(process.env.REACT_APP_YGY_MUTANT_ALIENS_CONTRACT) ||
    process.env.REACT_APP_YGY_MUTANT_ALIENS_CONTRACT;

export const StakingAddress = process.env.REACT_APP_STAKING_CONTRACT;
export const ZeroAddress = "0x0000000000000000000000000000000000000000";

export const APP_NETWORK = process.env.REACT_APP_CHAIN_NETWORK;
export const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY;
export const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY;
export const REWARD_TOKEN = process.env.REACT_APP_REWARD_TOKEN;

import ANTHROImage from "assets/images/anthro.png";
import HUMANImage from "assets/images/human.jpg";
import ANKOKUImage from "assets/images/ankoku.jpg";

export const ANTHROHidden =
  "https://gateway.ipfs.io/ipfs/Qmez8QrDYPxCHwaoUPEZVzDkz47L6LK47kVwYy3537YvPi/hidden.png";
export const HUMANHidden =
  "https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg";
export const ANKOKUHidden =
  "https://gateway.ipfs.io/ipfs://QmTUcH7nknQG9escy7Yz5y3ouKgyfk2hEdUNjwmXJDHQ3Z/hidden.jpg";

export const s0uImageFallback = {
  ANTHRO: ANTHROImage,
  HUMAN: HUMANImage,
  ANKOKU: ANKOKUImage,
  HUMANMUSIC: HUMANImage,
  [ANTHROAddress]: ANTHROImage,
  [HUMANAddress]: HUMANImage,
  [ANKOKUAddress]: ANKOKUImage,
  [HUMANMUSICAddress]: HUMANImage,
};

export const s0uNFTAddress = [
  ANTHROAddress,
  HUMANAddress,
  HUMANMUSICAddress,
  ANKOKUAddress,
  MUTANT_ALIENS_GENESISAddress,
  MUTANT_ALIENSAddress,
  YGY_MUTANT_ALIENSAddress
];
export const s0uNFTs = {
  ANTHRO: "ANTHRO",
  HUMAN: "HUMAN",
  ANKOKU: "ANKOKU",
  HUMANMUSIC: "HUMANMUSIC",
  MUTANT_ALIENS_GENESIS: "MUTANT ALIENS GENESIS",
  MUTANT_ALIENS: "MUTANT ALIENS",
  YGY_MUTANT_ALIENS: "YGY MUTANT ALIENS",
  [ANTHROAddress]: "ANTHRO",
  [HUMANAddress]: "HUMAN",
  [ANKOKUAddress]: "ANKOKU",
  [HUMANMUSICAddress]: "HUMANMUSIC",
  [MUTANT_ALIENS_GENESISAddress]: "MUTANT ALIENS GENESIS",
  [MUTANT_ALIENSAddress]: "MUTANT ALIENS",
  [YGY_MUTANT_ALIENSAddress]: "YGY MUTANT ALIENS",
};
export const AliensNFTs = {
  MUTANT_ALIENS_GENESIS: "MUTANT ALIENS GENESIS",
  MUTANT_ALIENS: "MUTANT ALIENS",
  YGY_MUTANT_ALIENS: "YGY MUTANT ALIENS",
  [MUTANT_ALIENS_GENESISAddress]: "MUTANT ALIENS GENESIS",
  [MUTANT_ALIENSAddress]: "MUTANT ALIENS",
  [YGY_MUTANT_ALIENSAddress]: "YGY MUTANT ALIENS",
}

export const s0uMoralis = {
  ANTHRO: "S0UANTHRO",
  HUMAN: "S0UHUMAN",
  ANKOKU: "S0UANKOKU",
  HUMANMUSIC: "HUMANMUSIC",
  [ANTHROAddress]: "S0UANTHRO",
  [HUMANAddress]: "S0UHUMAN",
  [ANKOKUAddress]: "S0UANKOKU",
  [HUMANMUSICAddress]: "HUMANMUSIC",
};

export const s0uProperties = {
  ACCESSORIES: "Attack (ATK)",
  ARMOR: "Defense (DEF)",
  ARMS: "Date Value",
  BACKGROUND: "Passion",
  BODY: "Passion Value",
  // CLOTHES: 'Occupation',
  // EYES: 'Attribute',
  HAIR: "Magic Power",
};

export const s0uVaults = {
  ANTHRO: 0,
  HUMAN: 1,
  HUMANMUSIC: 2,
  [ANTHROAddress]: 0,
  [HUMANAddress]: 1,
  [HUMANMUSICAddress]: 2,
};

export const NETWORK = {
  MAINNET: "0x1",
  SEPOLIA: "0xaa36a7",
};

export const S0U_THUMBNAIL = {
  ANTHRO: "Qmegx6fNNmMgt6RxnReGP9fxu2v3AtFNQXxXAooCNa735U",
  HUMAN: "QmQt8fcqGCQtpKPZPYTrYyidfbXdujVcf9cGVp86LGQmbU",
  ANKOKU: "QmXv89pKdAEQhVWc9hLsgA7VWDJ91L6pXt47iuDqidq54Z",
  HUMANMUSIC: "HUMANMUSIC",
  [ANTHROAddress]: "Qmegx6fNNmMgt6RxnReGP9fxu2v3AtFNQXxXAooCNa735U",
  [HUMANAddress]: "QmQt8fcqGCQtpKPZPYTrYyidfbXdujVcf9cGVp86LGQmbU",
  [ANKOKUAddress]: "QmXv89pKdAEQhVWc9hLsgA7VWDJ91L6pXt47iuDqidq54Z",
  [HUMANMUSICAddress]: "HUMANMUSIC",
};

export const FORMAT = {
  DATE: "MM-DD-YYYY HH:mm",
};

export const STORAGE_KEYS = {
  NFT: "s0uNFT",
  ACCESS_PASSWORD: "accessPassword",
};

export const DAILY_CHIPS = 1000;

export const ROULETTE_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  DONE: "DONE",
  ERROR: "ERROR",
}

export const BET_TYPE_WINNINGLINE = {
  SPLIT: "split",
  STREET: "street", 
  CORNER_BET: "corner_bet",
  DOUBLE_STREET: "double_street",
}
export const BET_TYPE_NUMBERBOARD = {
  INSIDE_WHOLE: "inside_whole",
  ZERO: "zero",
  OUTSIDE_COLUMN: "outside_column",
  OUTSIDE_DOZEN: "outside_dozen",
  OUTSIDE_OERB: "outside_oerb",
  OUTSIDE_LOW: "outside_low",
  OUTSIDE_HIGH: "outside_high",
}
