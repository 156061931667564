import React from "react";

import 'assets/css/roulette.css';
import classNames from "classnames";
import { Image, Tooltip } from "antd";
import { convertNFTImage } from "utils/web3.utils";
import { getAddress } from "ethers/lib/utils";
import { s0uImageFallback, s0uNFTs } from "utils/constant";

const BetTooltip = props => {
  const {
    data,
    open,
    children,
  } = props;

  const guessBet = <div
    className={classNames({
      ["nftBoxGrid"]: true,
      ["nftBoxGridTooltip"]: true,
    })}
  >
    <React.Fragment>
      {data?.map(item => {
        const { player } = item;
        if (player?.tokenId, player?.tokenAddress) {
          return (
            <div
              className={classNames({
                ["nftBox"]: true,
              })}
              key={player.tokenId}
            >
              {
                s0uNFTs[getAddress(player.tokenAddress)] === s0uNFTs.HUMANMUSIC
                  ? <video style={{ height: "100%", width: "auto", maxWidth: '100%' }} autoPlay muted controls={false}>
                    <source src={convertNFTImage(player.tokenAddress, player.tokenId, '') || "error"} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  : <Image
                    preview={false}
                    src={convertNFTImage(player.tokenAddress, player.tokenId, player.image) || "error"}
                    fallback={s0uImageFallback[player.tokenAddress]}
                    alt={player?.name}
                  />
              }
            </div>
          )
        } else {
          return null;
        }
      })}
    </React.Fragment >
  </div >

  return (
    <Tooltip
      {...open && data.length > 0 && { open: true }}
      // open={true}
      placement="top"
      title={data.length > 0 ? guessBet : ""}
    >
      {children}
    </Tooltip>
  );
}

export default BetTooltip;
