import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";

export const useSetting = () => {
  const [settingData, setSettingData] = useState();

  const {
    data: bets,
    error,
    isLoading,
    fetch: fetchSetting,
  } = useMoralisQuery(
    "Setting",
    query => query.descending("_created_at").limit(1),
    [],
    {
      live: true,
      onLiveEnter: (entity, all) => {
        return [...all, entity];
      },
      onLiveCreate: (entity, all) => {
        return [entity, ...all];
      },
      onLiveDelete: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveLeave: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveUpdate: (entity, all) => {
        const existed = all.find(e => e.id === entity.id);
        if (existed) {
          return all.map(e => (e.id === entity.id ? entity : e));
        } else {
          return [entity, ...all];
        }
      },
    }
  );

  const settingMapping = bets.map(item => {
    return {
      ...item,
      ...item.attributes,
    };
  });

  useEffect(() => {
    const updatedSetting = settingMapping[0];
    if (updatedSetting) {
      setSettingData(prev => {
        if (JSON.stringify(prev) !== JSON.stringify(updatedSetting)) {
          return updatedSetting;
        } else {
          return prev;
        }
      });
    } else {
      setSettingData(null);
    }
  }, [settingMapping]);

  return {
    settingData,
    error,
    isLoading: isLoading,
    fetchSetting,
  };
};
