import React from "react";

import 'assets/css/roulette.css';
import Wallet from "./Wallet/Wallet";
import { Button } from "antd";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { utils as ethersUtils } from "ethers";

const { getAddress } = ethersUtils;
const Header = props => {
  const {
    isLoading,
    isClaiming,
    isLeavingTable,
    disableClaim,
    showClaim,
    address,

    onClaimChips = () => { },
    onClaimTokens = () => { },
    onShowPlayers = () => { },
    onLeaveTable = () => { }
  } = props;

  const navigate = useNavigate();

  const admins = process.env.REACT_APP_ADMIN_ADDRESS.split(",");
  const isAdmin = admins.find(item => address && getAddress(item) === getAddress(address));

  return (
    <div className="headerWrap">
      <div className="wrapBtn">
        {
          showClaim
            ? <Button
              size="small"
              className={classNames({
                ["mainButton"]: true,
                ["disabled"]: isLoading || disableClaim,
              })}
              onClick={onClaimChips}
              disabled={isLoading || disableClaim || isClaiming}
            >
              {disableClaim ? 'Daily Claimed' : 'Daily Claim'}
            </Button>
            : null
        }
        <Button
          size="small"
          className={classNames({
            ["mainButton"]: true,
          })}
          onClick={onShowPlayers}
        >
          {'Select Player'}
        </Button>
        {/* <Button
            size="small"
            className={classNames({
              ["mainButton"]: true,
            })}
            onClick={onLeaveTable}
            disabled={isLeavingTable}
        >
          {'Leave Table'}
        </Button> */}
        {
          isAdmin
            ? <Button
              size="small"
              className={classNames({
                ["mainButton"]: true,
              })}
              onClick={() => navigate('/tournament')}
            >
              {'Tournament Management'}
            </Button>
            : null
        }
        {/* <Button
          size="small"
          className={classNames({
            ["mainButton"]: true,
            ["disabled"]: isLoading || disableClaim,
          })}
          onClick={onClaimTokens}
          disabled={isLoading}
        >
          {'Claim SOULS'}
        </Button> */}
      </div>
      <Wallet />
    </div>
  );
}

export default Header;
