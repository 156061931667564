import React, { useState, useEffect } from "react";
import { Button, Input, Space } from "antd";
import { useChain, useMoralis } from "react-moralis";
import Moralis from "moralis-v1";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import StoreHelper from "utils/storeHelper";
import { STORAGE_KEYS } from "utils/constant";
import { utils as ethersUtils } from "ethers";
import { useSetting } from "hooks/useSetting";

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "Roboto, sans-serif",
    color: "#fff",
    overflow: 'auto',
    background: "url('./images/bg_dealer.jpg')",
    backgroundSize: 'cover',
    width: "100vw",
    height: "100vh",
    backgroundSize: "cover",
    padding: '4rem',
  },
  boxCenter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: 'center',
    flexDirection: "column",
    flex: '1 0 auto'
  },
};

const MAINNET = process.env.REACT_APP_CHAIN_ID;
const { getCookie, setCookie } = StoreHelper;
const { getAddress } = ethersUtils;

const AuthLayout = () => {
  const moralisProps = useMoralis();
  const { authenticate, enableWeb3, isAuthenticated, isWeb3Enabled, isWeb3EnableLoading, account: address, user, logout, isInitialized } = moralisProps;

  const { settingData } = useSetting();

  const [authError, setAuthError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validPassword, setValidPassword] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const [inputPassword, setInputPassword] = useState(process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ACCESS_PASSWORD
    : ""
  );

  const { switchNetwork, chainId } = useChain();
  const isMainnet = chainId === MAINNET;
  const { account } = Moralis;

  const storePassword = getCookie(STORAGE_KEYS.ACCESS_PASSWORD);
  const admins = process.env.REACT_APP_ADMIN_ADDRESS.split(",");
  const isAdmin = admins.find(item => address && getAddress(item) === getAddress(address));

  useEffect(() => {
    if (!isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // if (isWeb3Enabled) authenticate();
  }, [isAuthenticated, isWeb3Enabled]);

  useEffect(() => {
    if (user?.attributes?.accounts && (address && !user?.attributes?.accounts.includes(address))) {
      logout();
    };
  }, [user, address, isAuthenticated, account, isMainnet]);

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled && !address) {
      logout();
    }
  }, [address, isAuthenticated, isWeb3Enabled]);

  useEffect(() => {
    if (storePassword === process.env.REACT_APP_ACCESS_PASSWORD) {
      setValidPassword(true);
    }
  }, [storePassword]);

  useEffect(() => {
    if (settingData?.showPassword && !storePassword) {
      setValidPassword(false);
      navigate("/");
    } else if (settingData && !settingData?.showPassword) {
      setValidPassword(true);
      !isAdmin && navigate("/");
    } else if (settingData?.showPassword && storePassword) {
      !isAdmin && navigate("/");
    } else {
      navigate("/");
    }
  }, [settingData]);

  useEffect(() => {
    if (location.pathname === "/tournament" && !isAdmin) {
      navigate("/");
    }
  }, [location]);

  useEffect(() => {
    isInitialized && validateSession()
  }, [isInitialized]);

  const validateSession = async () => {
    try {
      const response = await Moralis.Cloud.run("getPluginSpecs", {});
      console.log("validateSession: ", response);
    } catch (error) {
      console.log("validateSession error: ", error);
      logout();
    }
  }

  const handleAuth = async (provider) => {
    try {
      setAuthError(null);
      setIsAuthenticating(true);
      // Enable web3 to get user address and chain
      if (!isWeb3Enabled) {
        await enableWeb3({ throwOnError: true, provider });
      }
      const { account, chainId } = Moralis;
      if (isAuthenticated) {
        if (!isMainnet) {
          switchNetwork(MAINNET);
        }
      } else {
        if (!account) {
          throw new Error(
            "Connecting to chain failed, as no connected account was found"
          );
        }
        if (!chainId) {
          throw new Error(
            "Connecting to chain failed, as no connected chain was found"
          );
        }
        const { message } = await Moralis.Cloud.run("requestMessage", {
          address: account,
          chain: chainId,
          network: "evm",
        });

        // Authenticate and login via parse
        await authenticate({
          signingMessage: message,
          throwOnError: true,
        });
      }
    } catch (error) {
      setAuthError(error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const verifyPassword = () => {
    if (inputPassword === process.env.REACT_APP_ACCESS_PASSWORD) {
      setValidPassword(true);
      setCookie(STORAGE_KEYS.ACCESS_PASSWORD, inputPassword, {
        maxAge: 60 * 60 * 24
      })
    }
  }

  return (
    <React.Fragment>
      {
        validPassword
          ? <React.Fragment>
            {
              // address && isMainnet
              isAuthenticated && isMainnet
                ? <Outlet />
                : <div style={styles.content}>
                  <div style={styles.boxCenter}>
                    <Button
                      size="large"
                      className="mainButton"
                      onClick={() => handleAuth("metamask")}
                    >
                      {isAuthenticated && address
                        ? 'Switch to mainnet'
                        : isWeb3Enabled ? 'Sign In' : 'Connect Wallet'}
                    </Button>
                  </div>
                </div>
            }
          </React.Fragment>
          : <div style={styles.content}>
            <Space direction="vertical" style={{
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#2b2a2aad',
              padding: '1rem',
              borderRadius: 8
            }}>
              <div>
                <h1>Under maintenance.</h1>
              </div>
              <div>
                We will announce the reopening time.
              </div>
              <Input.Password
                style={{ height: 48, fontSize: 16 }}
                placeholder="input password"
                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                onChange={_event => setInputPassword(() => _event.target.value)}
              />
              <Button
                size="large"
                className="mainButton"
                onClick={verifyPassword}
              >
                {'Access'}
              </Button>
            </Space>
          </div>
      }
    </React.Fragment>
  );
};

export default AuthLayout;
