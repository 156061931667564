import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import classNames from 'classnames';

import 'assets/css/roulette.css';
import NumberBoard from "./NumberBoard";
import WinningLines from "./WinningLines";
import Wheel from "./Wheel";
import { utils as ethersUtils } from "ethers";
import { useStaking } from "hooks/useStaking";
import { s0uVaults, ANTHROAddress, HUMANAddress, HUMANMUSICAddress, s0uNFTAddress, DAILY_CHIPS, ANKOKUAddress, MUTANT_ALIENS_GENESISAddress, MUTANT_ALIENSAddress, YGY_MUTANT_ALIENSAddress, BET_TYPE_NUMBERBOARD, BET_TYPE_WINNINGLINE } from "utils/constant";
import Header from "./Header";
import { useMoralis, useNewMoralisObject } from "react-moralis";
import axios from "axios";
import { Alert, message } from "antd";
import { transferGameRewardToken } from "utils/web3.utils";
import StoreHelper from "utils/storeHelper";
import AvatarModal from "./modal/avatarModal";
import Countdown from "react-countdown";
import { TrophyOutlined } from "@ant-design/icons";
import LeaderBoard from "./modal/leaderBoard";
import moment from "moment";
import { usePlayer } from "hooks/usePlayer";
import { useDiscordData } from "hooks/useDiscordData";
import { useNavigate, useParams } from "react-router-dom";
import { useTournament } from "hooks/useTournament";
import { useBet } from "hooks/useBet";
import { useRoulette } from "hooks/useRoulette";
import { numToAbbreviation } from "utils";
import BetTooltip from "./BetTooltip";
import { LoadingBG } from "assets/icons/svgIcon";
import { useTable } from "hooks/useTable";
import TablePlayers from "./TablePlayers";

const { getLocalObject, setLocalObject, getCookie } = StoreHelper;
const { getAddress, parseEther } = ethersUtils;
const Roulette = (_props) => {
  const navigate = useNavigate();

  const chips = [
    { value: 10, label: '10', color: 'red' },
    { value: 100, label: '100', color: 'blue' },
    { value: 1000, label: '1000', color: 'orange' },
    { value: 5000, label: '5k', color: 'gold' },
    { value: 'all', label: 'all', color: 'betAll' },
    { value: 'clear', label: 'clear', color: 'clearBet' },
  ];
  const wheelnumbersAC = [0, 26, 3, 35, 12, 28, 7, 29, 18, 22, 9, 31, 14, 20, 1, 33, 16, 24, 5, 10, 23, 8, 30, 11, 36, 13, 27, 6, 34, 17, 25, 2, 21, 4, 19, 15, 32];
  const numRed = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
  const { id: tableId } = useParams();

  const { user: userInfo, account: address, chainId, setUserData, refetchUserData, Moralis, logout } = useMoralis();
  const { data: discordData } = useDiscordData();

  const containerRef = useRef(null);
  const boardRef = useRef(null);
  const wheelRef = useRef(null);

  const [bankValue, setBankValue] = useState(0);
  const [prevBankValue, setPrevBankValue] = useState(0);
  const [currentBet, setCurrentBet] = useState(0);
  const [wager, setWager] = useState(10);
  const [lastWager, setLastWager] = useState(0);

  const [bets, setBets] = useState([]);
  const [numbersBet, setNumbersBet] = useState([]);
  const [previousNumbers, setPreviousNumbers] = useState([]);
  const [nftBalance, setNFTBalance] = useState([]);

  const [isClaimingDaily, setIsClaimingDaily] = useState(false);
  const [isLeavingTable, setIsLeavingTable] = useState(false);

  const [isBankrupt, setIsBankrupt] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFetchNFTs, seIsFetchNFTs] = useState(false);
  const [isFetchStakedNFTs, setIsFetchStakedNFTs] = useState(false);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [showBoard, setShowBoard] = useState(false);
  const [disabledBoard, setDisabledBoard] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const [winResult, setWinResult] = useState();
  const [selectedChip, setSelectedChip] = useState(chips[0]);
  const [stakedTokens, setStakedTokens] = useState({});
  const [selectedNFT, setSelectedNFT] = useState(getLocalObject(`${address}`));

  const [isJoin, setIsJoin] = useState(false);

  const isWheelSpin = !!wheelRef?.current?.style?.cssText && wheelRef?.current?.style?.cssText !== ''

  const ownedS0UNFTs = useMemo(() => {
    let count = nftBalance.length;
    for (const s0uTokens of Object.values(stakedTokens)) {
      count += s0uTokens.length;
    }
    return count;
  }, [nftBalance, stakedTokens]);

  const ownedStakedS0UNFTs = useMemo(() => {
    let s0uStaked = [];
    for (const [key, value] of Object.entries(stakedTokens)) {
      for (const _tokenId of value) {
        s0uStaked = [...s0uStaked, { token_address: key, token_id: `${_tokenId}` }]
      }
    }
    return s0uStaked;
  }, [stakedTokens]);

  const validateSession = async () => {
    try {
      const response = await Moralis.Cloud.run("getPluginSpecs", {});
      console.log("validateSession: ", response);
    } catch (error) {
      console.log("validateSession error: ", error);
      logout();
    }
  }

  const handleJoinTable = () => {
    try {
      setIsJoin(true);
      setLoading(true);
      if (s0uPlayer?.disabled) {
        setLoading(false);
        navigate('/');
        return;
      }
      const response = Moralis.Cloud.run("joinTable", { playerId: s0uPlayer.id });
      // The user has not claimed in today
      response.then(result => {
        if (result.statusCode === 200) {
          messageApi.open({
            type: 'success',
            content: `Join success`,
          });
          fetchTable();
          if (tableId !== result.data.id) {
            navigate(`/table/${result.data.id}`);
          }
        } else {
          messageApi.open({
            type: 'warning',
            content: `Join fail`,
          });
          navigate('/');
        }
        setLoading(false);
      })
    } catch (error) {
      setIsJoin(false);
      setLoading(false);
    }
  }

  const { player: s0uPlayer, getPlayerDetail, fetchPlayer, isLoading: playerLoading, error: fetchPlayerError, isGuess } = usePlayer({ ...selectedNFT, wallet: address, nftBalance: [...nftBalance, ...ownedStakedS0UNFTs], address, tableId });

  const stakingContract = useStaking();
  const [messageApi, contextHolder] = message.useMessage();

  const { chips: userChips = 0, tokenAddress, tokenId, id: playerId, image } = s0uPlayer || {};
  const userAvatar = { tokenAddress, tokenId, image };

  let ballTrack = document.getElementsByClassName('ballTrack')[0];

  const [limit] = useState(30);
  const currentDate = moment().utc().valueOf();

  const { activeTournament, isLoading: tournamentLoading, upcomingTournament, fetchTournament } = useTournament();
  const { activeRoulette, isLoading: rouletteLoading, fetchRoulette } = useRoulette();
  const { playerBet, fetchPlayerBet, tableBets } = useBet({ tableId, rouletteId: activeRoulette?.id, playerId });
  const { tablePlayers, isLoading: isLoadingTablePlayers, fetchTablePlayers, fetchTable } = useTable({ tableId, discordData });

  const guessBets = useMemo(() => {
    return tableBets.map(bet => {
      return {
        ...bet,
        player: tablePlayers.length > 0 ? tablePlayers.find(player => player.id === bet.playerId) : null
      };
    })
  }, [tablePlayers, tableBets])

  const allowClaimReward = useMemo(() => {
    if (userChips >= 10 && Object.values(stakedTokens).flat().length > 0) {
      return true;
    } else {
      return false;
    }
  }, [userChips, stakedTokens]);

  const betNumberboards = useMemo(() => {
    return bets.filter(bet => Object.values(BET_TYPE_NUMBERBOARD).includes(bet.type))
  }, [bets]);

  const betWinninglines = useMemo(() => {
    return bets.filter(bet => Object.values(BET_TYPE_WINNINGLINE).includes(bet.type))
  }, [bets]);

  const fetchMyNFTs = async () => {
    try {
      seIsFetchNFTs(true);
      const options = {
        params: {
          limit: 100,
          chain: chainId,
          format: 'decimal',
          token_addresses: [ANTHROAddress, HUMANAddress, HUMANMUSICAddress, ANKOKUAddress, MUTANT_ALIENS_GENESISAddress, MUTANT_ALIENSAddress, YGY_MUTANT_ALIENSAddress]
        },
        headers: { accept: 'application/json', 'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY }
      };
      const res = await axios.get(`https://deep-index.moralis.io/api/v2/${address}/nft`, options);

      if (res?.data?.result) {
        setNFTBalance(res.data.result.map(item => {
          const metadata = JSON.parse(item.metadata);
          return {
            ...item,
            metadata
          }
        }));
      } else {
        setNFTBalance([]);
      }
      seIsFetchNFTs(false);
    } catch (error) {
      console.log("fetchMyNFTs error: ", error);
      setNFTBalance([]);
      seIsFetchNFTs(false);
    }
  }

  const updateNumOfNFT = async _numOfNFT => {
    try {
      if (!s0uPlayer || s0uPlayer.numOfNFT === _numOfNFT) {
        return;
      }
      await Moralis.Cloud.run("updateNumOfNFT", { id: s0uPlayer.id, numOfNFT: _numOfNFT });
    } catch (error) {
      console.log("updateNumOfNFT error: ", error);
    }
  }

  useEffect(() => {
    if (!getLocalObject(`${address}`)) {
      setIsAvatarModalOpen(true);
    }
  }, [getLocalObject(`${address}`)])

  useEffect(() => {
    if ((userChips || userChips === 0) && !playerLoading) {
      setBankValue(() => userChips);
    }
  }, [userChips, playerLoading])

  useEffect(() => {
    if (address && chainId) {
      fetchMyNFTs();
    }
  }, [address, chainId])

  useEffect(() => {
    if (stakingContract && address) {
      getVaults();
    }
  }, [stakingContract, address])

  useEffect(() => {
    if (
      playerBet &&
      (playerBet.winningSpin || playerBet.winningSpin === 0) &&
      (playerBet.winValue || playerBet.winValue === 0) &&
      (playerBet.payout || playerBet.payout === 0)
    ) {
      handleSpinResult({
        winningSpin: playerBet.winningSpin,
        winValue: playerBet.winValue,
        payout: playerBet.payout
      })
    }
  }, [playerBet]);

  useEffect(() => {
    if (s0uPlayer && !isJoin && activeTournament) {
      handleJoinTable();
    }
  }, [s0uPlayer, activeTournament])

  useEffect(() => {
    if (!isFetchNFTs && !isFetchStakedNFTs && !playerLoading) {
      console.log("s0uPlayer: ", s0uPlayer, ownedS0UNFTs);
      updateNumOfNFT(ownedS0UNFTs);
    }
  }, [isFetchNFTs, isFetchStakedNFTs, ownedS0UNFTs, playerLoading])

  useEffect(() => {
    if (!activeTournament) {
      console.log("End activeTournament: ", activeTournament);
      setTimeout(() => {
        stopSpinWheel();
        fetchRoulette();
      }, 1000);
    }
  }, [activeTournament])

  const refreshAllData = () => {
    fetchMyNFTs();
    getVaults();
  }

  const disabledRoulette = !s0uPlayer || !activeTournament || isSpinning || isWheelSpin || !!playerBet || isGuess || !tableId;
  const loading = isLoading || playerLoading || tournamentLoading;

  const getVaults = async () => {
    if (stakingContract) {
      try {
        setIsFetchStakedNFTs(true);
        let stakedItems = {};
        for (let tokenAddress of s0uNFTAddress) {
          const stakingVault = s0uVaults[tokenAddress];
          if (stakingVault || stakingVault === 0) {
            const response = await stakingContract.tokensOfOwner(getAddress(address), s0uVaults[tokenAddress]);

            stakedItems = {
              ...stakedItems,
              [tokenAddress]: response.map(item => item.toNumber())
            }
          }
        }
        setStakedTokens(stakedItems);
        setIsFetchStakedNFTs(false);
        return stakedItems;
      } catch (error) {
        console.error('error: ', error);
        setIsFetchStakedNFTs(false);
        return {};
      }
    }
  }

  const resetGame = () => {
    setBankValue(() => userChips);
    setCurrentBet(0);
    // setWager(10);
    setBets([]);
    setNumbersBet([]);
    // setPreviousNumbers([]);
    setIsBankrupt(false);
    setWinResult(null);
  }

  const renderChip = ({ displayBet: _displayBet, guessBets: _guessBets }) => {
    if (_displayBet || _guessBets.length > 0) {
      const betAMT = _displayBet?.amt;
      const chipColour = (betAMT < 100) ? 'red' : ((betAMT < 1000) ? 'blue' : ((betAMT < 5000) ? 'orange' : 'gold'));
      const totalAMT = _guessBets.reduce((total, currentValue) => {
        return total + currentValue.amt;
      }, 0);
      return <React.Fragment>
        <BetTooltip data={_guessBets} open={activeRoulette && activeTournament && disabledRoulette}>
          {
            _guessBets.length > 0 && !_displayBet
              ? <div
                className={classNames({
                  "chip": true,
                  "chipGuess": true,
                })}
              >
                <span className="chipSpanGuess">
                  {numToAbbreviation(totalAMT)}
                </span>
              </div>
              : <div
                className={classNames({
                  "chip": true,
                  [chipColour]: true,
                })}
              >
                <span className="chipSpan">
                  {numToAbbreviation(betAMT)}
                </span>
              </div>
          }
        </BetTooltip>
      </React.Fragment>;
    } else {
      return null;
    }
  };

  const renderGuessChip = (_guessBets) => {
    if (_guessBets.length > 0) {
      const totalAMT = _guessBets.reduce((total, currentValue) => {
        return total + currentValue.amt;
      }, 0);
      return <React.Fragment>
        <BetTooltip data={_guessBets} open={activeRoulette && activeTournament && disabledRoulette}>
          <div
            className={classNames({
              "chip": true,
              "chipGuess": true,
            })}
          >
            <span className="chipSpanGuess">
              {numToAbbreviation(totalAMT)}
            </span>
          </div>
        </BetTooltip>
      </React.Fragment>;
    } else {
      return null;
    }
  };

  const removeBet = (_num, _type, _odd) => {
    const currentWager = (wager === 0) ? 100 : wager;
    setBets(prevBets => {
      let haveExistedBet = false;
      const updatedBets = prevBets.map(item => {
        if (item.numbers === _num && item.type === _type && item.amt !== 0) {
          haveExistedBet = true;

          const currentAMT = item.amt - currentWager >= 0 ? item.amt - currentWager : 0;
          const updateWager = currentWager > item.amt ? item.amt : currentWager;
          const updateBet = currentBet - updateWager;

          setBankValue(bankValue + updateWager);
          setCurrentBet(updateBet >= 0 ? updateBet : 0);

          return {
            ...item,
            amt: currentAMT
          };
        } else {
          return item;
        }
      });

      if (haveExistedBet) {
        return [...updatedBets];
      } else {
        return [...prevBets];
      }
    });
  }

  const setBet = (_num, _type, _odd) => {
    setLastWager(wager);
    const currentWager = (bankValue < wager) ? bankValue : wager;
    // setWager(currentWager);
    if (currentWager > 0) {
      setBankValue(bankValue - currentWager);
      setCurrentBet(currentBet + currentWager);

      setBets(prevBets => {
        let haveExistedBet = false;

        const updatedBets = prevBets.map(item => {
          if (item.numbers === _num && item.type === _type) {
            haveExistedBet = true;
            return {
              ...item,
              amt: item.amt + currentWager
            };
          } else {
            return item;
          }
        });

        if (haveExistedBet) {
          return [...updatedBets];
        } else {
          const newBet = {
            amt: currentWager,
            type: _type,
            odds: _odd,
            numbers: _num
          };

          return [...prevBets, newBet];
        }
      });

      let numArray = _num.split(',').map(Number);
      for (let i = 0; i < numArray.length; i++) {
        if (!numbersBet.includes(numArray[i])) {
          numbersBet.push(numArray[i]);
        }
      }
    }
  }

  const onSelectChip = (chip) => {
    console.log("chip: ", chip);
    switch (chip.value) {
      case 'clear':
        onClearBets();
        break;
      case 'all':
        setSelectedChip(chip);
        setWager(userChips);
        break;
      default:
        setSelectedChip(chip);
        setWager(chip.value);
        break;
    }
  }

  const onClearBets = () => {
    setBankValue(_bankValue => userChips);
    fetchPlayer();
    setCurrentBet(0);
    clearBet();
  };
  const spinWheel = () => {
    wheelRef.current.style.cssText = 'animation: wheelRotate 5s linear infinite;';
    ballTrack.style.cssText = 'animation: ballRotate 1s linear infinite;';
  }

  const onSpinFail = () => {
    validateSession();
    setIsSpinning(false);
    setBankValue(_bankValue => userChips);
    fetchPlayer();
    wheelRef.current.style.cssText = '';
    ballTrack.style.cssText = 'animation: ballStop 1s linear;';
    messageApi.open({
      type: 'warning',
      content: 'Spin failed',
    });
    setCurrentBet(0);
    clearBet();
  }

  const stopSpinWheel = (winningSpin) => {
    setIsSpinning(false);
    resetGame();
    if (winningSpin) {
      let degree
      for (let i = 0; i < wheelnumbersAC.length; i++) {
        if (wheelnumbersAC[i] === winningSpin) {
          degree = (i * 9.73) + 362;
        }
      }
      let style = document.createElement('style');

      style.type = 'text/css';
      style.innerText = '@keyframes ballStop {from {transform: rotate(0deg);}to{transform: rotate(-' + degree + 'deg);}}';
      document.head.appendChild(style);
      setTimeout(() => {
        ballTrack.style.cssText = 'animation: ballStop 3s linear;';
      }, 1000);
      setTimeout(() => {
        ballTrack.style.cssText = 'transform: rotate(-' + degree + 'deg);';
      }, 4000);
      setTimeout(() => {
        wheelRef.current.style.cssText = '';
        style.remove();
        setPreviousNumbers(prev => [...prev, winningSpin]);
      }, 5000);
    } else {
      if (wheelRef.current) {
        wheelRef.current.style.cssText = '';
      }
      if (ballTrack) {
        ballTrack.style.cssText = 'animation: ballStop 1s linear;';
      }
      fetchTablePlayers();
    }
  }

  const clearBet = () => {
    setBets([]);
    setNumbersBet([]);
  }

  const validateDailyClaim = async () => {
    setIsClaimingDaily(true);
    if (!s0uPlayer) {
      messageApi.open({
        type: 'warning',
        content: 'You must select Singularity 0 Universe Player to claim chips',
      });
      return;
    }
    const result = await getPlayerDetail(s0uPlayer.id);

    if (result) {
      // If lastCheckInDate exists and is the same as the current date, the user has already checked in
      if (ownedS0UNFTs <= 0) {
        messageApi.open({
          type: 'warning',
          content: 'You must have or staking Singularity 0 Universe NFTs to claim chips',
        });
        setIsClaimingDaily(false);
        return true;
      }
      try {
        const response = await Moralis.Cloud.run("dailyClaimChips", { id: s0uPlayer.id });
        // The user has not claimed in today
        if (response) {
          messageApi.open({
            type: 'success',
            content: `Claim success: ${DAILY_CHIPS} chips`,
          });
        } else {
          messageApi.open({
            type: 'warning',
            content: `Claim failed...`,
          });
        }
        clearBet();
        setIsClaimingDaily(false);
      } catch (error) {
        console.log("error: ", error, error.message);
        messageApi.open({
          type: 'error',
          content: error.message,
        });
        clearBet();
        setIsClaimingDaily(false);
      }


      clearBet();
      setIsClaimingDaily(false);
      return false;
    }
    // else {
    //   if (ownedS0UNFTs <= 0) {
    //     messageApi.open({
    //       type: 'warning',
    //       content: 'You must own or staking Singularity 0 Universe NFTs to claim chips',
    //     });
    //     setIsClaimingDaily(false);
    //     return true;
    //   } else {
    //     const response = await Moralis.Cloud.run("dailyClaimChips", { id: s0uPlayer.id });
    //     // The user has not claimed in today
    //     if (response) {
    //       messageApi.open({
    //         type: 'success',
    //         content: `Claim success: ${DAILY_CHIPS} chips`,
    //       });
    //     } else {
    //       messageApi.open({
    //         type: 'warning',
    //         content: `Claim failed...`,
    //       });
    //     }
    //     clearBet();
    //     setIsClaimingDaily(false);
    //   }
    // }
  }

  const leaveTable = async () => {
    if (s0uPlayer) {
      setIsLeavingTable(true)
      console.log(tableId, 'asd')
      const response = await Moralis.Cloud.run("leaveTable", { tableId: tableId, playerId: s0uPlayer.id });
      // The user has not claimed in today
      if (!response) {
        messageApi.open({
          type: 'warning',
          content: `Leave Table Fail`,
        });
      }
      navigate('/')
      setIsLeavingTable(false)
    }
  }

  const onClaimTokens = async () => {
    setLoading(true);
    const result = await getPlayerDetail();
    if (result) {
      const { dailyClaim: currentDailyClaim, chips: currentChips = 0 } = result || {};

      const resultTransfer = await transferGameRewardToken({
        amount: parseEther(`${parseInt(currentChips / 10)}`),
        receive_address: address
      });

      if (resultTransfer.success) {
        messageApi.open({
          type: 'success',
          content: `Claim success: ${ownedS0UNFTs * 100} chips`,
        });
        clearBet();
      } else {
        messageApi.open({
          type: 'error',
          content: `Claim error`,
        });
        clearBet();
      }
    }
    setLoading(false);
  }

  const onSelectAvatar = async _nft => {
    await leaveTable();
    setSelectedNFT(_nft);
    setLocalObject(`${address}`, _nft);
    setIsJoin(false);
    setIsAvatarModalOpen(false);
  }

  // Renderer callback with condition
  const rendererCountdown = ({ completed, formatted }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      // Render a completed state
      return <span>Tournament Ended</span>;
    } else {
      // Render a countdown
      return <span>Tournament Ends in {`${days && days > 0
        ? days > 1
          ? days + ' days'
          : days + ' day'
        : ''}`} {hours}:{minutes}:{seconds}</span>;
    }
  };
  const rendererUpcomingCountdown = ({ completed, formatted }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      // Render a completed state
      return <span></span>;
    } else {
      // Render a countdown
      return <span>Tournament Start in next {`${days && days > 0
        ? days > 1
          ? days + ' days'
          : days + ' day'
        : ''}`} {hours}:{minutes}:{seconds}</span>;
    }
  };
  const { isSaving, save: savePlayerBet } = useNewMoralisObject('PlayerBet');

  // const createPlayerBet = _params => {
  //   const { bets, tableId, rouletteId } = params;

  //   for(const player of tablePlayers) {
  //     const params = {
  //       bets, 
  //       tableId, 
  //       rouletteId,
  //       playerId: player.id
  //     };
  //     savePlayerBet(params);
  //     console.log("createPlayerBet: ", params);
  //   }
  // };
  const onBet = async () => {
    try {
      setPrevBankValue(bankValue);
      await Moralis.Cloud.run("onBet", { id: s0uPlayer.id, bets: bets, tableId, rouletteId: activeRoulette?.id });
      messageApi.open({
        type: 'success',
        content: `Bet success!!!`,
      });
      fetchPlayerBet();
      // createPlayerBet();
    } catch (error) {
      console.log("onBet error: ", error);
      messageApi.open({
        type: 'error',
        content: `An error happen when player bet`,
      });
    }
  }
  const spin = async () => {
    try {
      setIsSpinning(true);
      setPrevBankValue(bankValue);
      spinWheel();
      const { winningSpin, winValue, payout } = await Moralis.Cloud.run("onSpinWheel", { id: s0uPlayer.id, bets: bets });
      let totalWin = winValue;
      stopSpinWheel(winningSpin);

      if ((!winningSpin && winningSpin !== 0) || (!winValue && winValue !== 0)) {
        onSpinFail();
        return;
      }

      setTimeout(() => {
        if (winValue && winValue > 0) {
          let betTotal = 0;
          for (let i = 0; i < bets.length; i++) {
            let numArray = bets[i].numbers.split(',').map(Number);
            betTotal = betTotal + bets[i].amt;
            if (numArray.includes(winningSpin)) {
              totalWin += bets[i].amt;
            }
          }
          setWinResult(() => {
            return {
              winningSpin, winValue: totalWin, betTotal, payout: payout || payout === 0 ? payout : winValue - betTotal
            };
          });
        }

        setCurrentBet(0);
        // setPreviousNumbers(prev => [...prev, winningSpin]);
        setBets([]);
        setNumbersBet([]);
        setDisabledBoard(false);
        setWager(() => lastWager);
        if (payout === 0) {
          setTimeout(() => {
            setBankValue(_bankValue => userChips);
            fetchPlayer();
          }, 100);
        }
      }, 8000);
      setIsSpinning(false);
    } catch (error) {
      onSpinFail();
    }
  }
  const startSpin = async () => {
    //// Auto bet
    if (!playerBet && wager > 0 && currentBet !== 0) {
      await onBet();
    }
    setIsSpinning(true);
    setPrevBankValue(bankValue);
    spinWheel();
  };
  const handleSpinResult = _result => {
    try {
      const { winningSpin, winValue, payout } = _result;
      console.log("onSpinWheel response: ", { winningSpin, winValue, payout });
      let totalWin = winValue;

      if ((!winningSpin && winningSpin !== 0) || (!winValue && winValue !== 0)) {
        onSpinFail();
        return;
      }

      setTimeout(() => {
        if (winValue && winValue > 0) {
          let betTotal = 0;
          for (let i = 0; i < bets.length; i++) {
            let numArray = bets[i].numbers.split(',').map(Number);
            betTotal = betTotal + bets[i].amt;
            if (numArray.includes(winningSpin)) {
              totalWin += bets[i].amt;
            }
          }
          setWinResult(() => {
            return {
              winningSpin, winValue: totalWin, betTotal, payout: payout || payout === 0 ? payout : winValue - betTotal
            };
          });
        }

        setCurrentBet(0);
        // setPreviousNumbers(prev => [...prev, winningSpin]);
        setBets([]);
        setNumbersBet([]);
        setDisabledBoard(false);
        setWager(() => lastWager);
        if (payout === 0) {
          setTimeout(() => {
            setBankValue(_bankValue => userChips);
            fetchPlayer();
          }, 100);
        }
      }, 5000);
      setIsSpinning(false);
    } catch (error) {
      onSpinFail();
    }
  }

  return (
    <div ref={containerRef} id="container">
      {contextHolder}
      {
        loading
          ? <div className="loadingBg">
            <LoadingBG />
          </div>
          : null
      }
      <Header
        onClaimChips={validateDailyClaim}
        onClaimTokens={onClaimTokens}
        onLeaveTable={leaveTable}
        onShowPlayers={() => setIsAvatarModalOpen(true)}
        isLoading={isFetchStakedNFTs || isFetchNFTs}
        isClaiming={isLoading || isClaimingDaily}
        isLeavingTable={isLoading || isClaimingDaily || isLeavingTable}
        isValidClaim={allowClaimReward}
        showClaim={!!s0uPlayer}
        address={address}
      />
      <div className="pageTitle">
        {
          activeTournament?.endTime
            ? <Countdown
              date={(new Date(activeTournament?.endTime))}
              renderer={rendererCountdown}
              intervalDelay={0}
              precision={3}
              zeroPadTime={2}
              daysInHours={false}
              onComplete={() => {
                setTimeout(() => {
                  stopSpinWheel();
                }, 5000);
              }}
            />
            : upcomingTournament
              ? <Countdown
                date={(new Date(upcomingTournament?.startTime))}
                renderer={rendererUpcomingCountdown}
                intervalDelay={0}
                precision={3}
                zeroPadTime={2}
                daysInHours={false}
                onComplete={fetchTournament}
              />
              : <p>Waiting for upcoming Tournament</p>
        }
      </div>
      <div className={classNames({
        ["containerBody"]: true,
      })}
      >
        <Wheel
          wheelRef={wheelRef}
          chips={chips}
          bankValue={isWheelSpin ? prevBankValue : bankValue}
          currentBet={currentBet}
          wager={wager}
          selectedChip={selectedChip}
          disabled={isSpinning || playerLoading || isWheelSpin || disabledRoulette}
          numberOfNfts={[...nftBalance, ...ownedStakedS0UNFTs].length}
          onSelectChip={onSelectChip}
          spin={onBet}
          userAvatar={userAvatar}
          discordname={userInfo?.attributes?.discordname}
          activeRoulette={activeRoulette}
          setIsAvatarModalOpen={setIsAvatarModalOpen}
          startSpin={startSpin}
          endSpin={stopSpinWheel}
        />
        <div
          ref={boardRef}
          id="betting_board"
          style={disabledBoard ? { pointerEvents: 'none', opacity: 0.6 } : {}}
          className={classNames({
            ["disabled"]: disabledRoulette
          })}
        >
          <WinningLines
            betData={betWinninglines}
            guessData={guessBets}
            renderChip={renderChip}
            setBet={setBet}
            removeBet={removeBet}
          />
          <NumberBoard
            betData={betNumberboards}
            guessData={guessBets}
            renderChip={renderChip}
            setBet={setBet}
            removeBet={removeBet} />
          <div className="pnBlock">
            <div
              id="pnContent"
              onWheel={e => {
                e.target.scrollLeft += e.deltaY;
              }}
            >
              {
                previousNumbers.map((item, index) => {
                  return <span
                    key={`pnContent-${item}-${index}`}
                    className={classNames({
                      "pnRed": numRed.includes(item),
                      "pnBlack": !numRed.includes(item),
                      "pnGreen": item === 0,
                    })}
                  >
                    {item.toLocaleString("en-GB")}
                  </span>
                })
              }
            </div>
          </div>
          {/* //player table */}
          <TablePlayers data={tablePlayers} isLoading={isLoadingTablePlayers} />
        </div>
        <div className="leaderBoard" onClick={() => setShowBoard(true)}>
          <div className="lbTitle">Leader board</div>
          <div>
            <TrophyOutlined />
          </div>
        </div>
        {
          isBankrupt
            ? <div id="notification">
              <span className="nSpan">Bankrupt</span>
              <div className="nBtn" onClick={resetGame}>Play again</div>
            </div>
            : null
        }
        {
          winResult
            ? <div id="notification">
              <div className="nSpan">WIN</div>
              <div className={classNames({
                "nSpan": true,
                "pnRed": numRed.includes(winResult.winningSpin),
                "pnBlack": !numRed.includes(winResult.winningSpin),
              })}>{winResult.winningSpin}</div>

              <div className="resultBlock">
                <div className="nsWinBlock">{`Bet: ${winResult.betTotal}`}</div>
                <div className="nsWinBlock">{`Win: ${winResult.winValue}`}</div>
                <div className="nsWinBlock">{`Payout: ${winResult.payout}`}</div>
              </div>
              <div className="nBtn" onClick={() => setWinResult(() => null)}>Play again</div>
            </div>
            : null
        }
      </div>
      <AvatarModal
        open={isAvatarModalOpen}
        address={address}
        onSelectAvatar={onSelectAvatar}
        nftBalance={[...nftBalance, ...ownedStakedS0UNFTs]}
        onCancel={() => setIsAvatarModalOpen(false)}
        isLoading={isFetchStakedNFTs || isFetchNFTs}
        setUserData={setUserData}
        userInfo={userInfo}
      />
      <LeaderBoard
        open={showBoard}
        onClose={() => setShowBoard(false)}
        discordData={discordData} s0uPlayer={s0uPlayer}
        // showLeaderboard={activeTournament?.showLeaderboard}
        showLeaderboard={true}
      />
      {
        isGuess
          ? <div style={{ position: "fixed", top: 16 }}>
            <Alert message="You are excluded from the whitelist." type="warning" />
          </div>
          : null
      }
    </div >
  );
}

export default Roulette;
