export const LoadingBG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    style={{
      margin: "auto",
      display: "block",
      zIndex: 1,
      position: "relative",
      shapeRendering: "auto",
    }}
    width="1920"
    height="963"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 1920 963"
  >
    <g transform="translate(960,481.5) scale(1,1) translate(-960,-481.5)">
      {" "}
      <defs>
        <symbol id="bestagon-342510403153130245143">
          <path
            d="M50.03894783066486 0L97.57594826979648 27.445500000000003L97.5759482697965 82.3365L50.03894783066486 109.78200000000001L2.5019473915332426 82.33650000000003L2.5019473915332355 27.445499999999996Z"
            fill="inherit"
          >
            {" "}
          </path>
        </symbol>
      </defs>
      <use
        href="#bestagon-342510403153130245143"
        x="-40.77895661329717"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.431484837109954s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="-90.81790444396202"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7590252170941358s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="-40.77895661329717"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.48859285717472867s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="-90.81790444396202"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8405886724531029s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="-40.77895661329717"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.03016119977842635s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="-90.81790444396202"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6683601606964618s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="-40.77895661329717"
        y="924.48"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7305607270303662s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="9.259991217367691"
        y="144.45"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.1301754840395737s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="59.298939048032565"
        y="231.12"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.9863722964108979s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="9.259991217367691"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.549295682776985s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="59.298939048032565"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9424547258460332s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="9.259991217367691"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6718365857514601s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="59.298939048032565"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.4817504548046805s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="9.259991217367691"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6742002667019369s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="59.298939048032565"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6883853579644534s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="9.259991217367691"
        y="837.8100000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9876799384061314s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="59.298939048032565"
        y="924.48"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.44357234380642785s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="159.37683470936227"
        y="57.78"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9280542207311107s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="159.37683470936227"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6191546667912116s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="109.33788687869742"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8681212000771472s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="159.37683470936227"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4045304660916376s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="109.33788687869742"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.289323604328589s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="159.37683470936227"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.30648526977008617s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="109.33788687869742"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.6579997712127832s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="159.37683470936227"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4245983684952814s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="109.33788687869742"
        y="837.8100000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4800031600753005s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="209.41578254002715"
        y="144.45"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8380250282651756s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="259.454730370692"
        y="231.12"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.45566362829945417s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="209.41578254002715"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9850574912672605s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="259.454730370692"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.017539824423965733s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="209.41578254002715"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.5564136023107098s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="259.454730370692"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8014337056201049s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="209.41578254002715"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4843816374166527s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="259.454730370692"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4946407117503022s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="209.41578254002715"
        y="837.8100000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.0850509014787026s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="259.454730370692"
        y="924.48"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.7791951041709622s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="309.49367820135683"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.11413798928687413s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="359.5326260320217"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.5504704449981404s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="309.49367820135683"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.4269078056289626s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="359.5326260320217"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.06624998398937532s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="309.49367820135683"
        y="664.47"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8402961172499728s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="359.5326260320217"
        y="751.14"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.0648951113050815s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="409.57157386268653"
        y="144.45"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.05291507213663893s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="459.61052169335136"
        y="231.12"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.9740998886829577s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="409.57157386268653"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.389289144676737s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="459.61052169335136"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.3686722659704005s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="409.57157386268653"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.5224799655468284s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="459.61052169335136"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.6267122356321235s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="409.57157386268653"
        y="664.47"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.382601328373044s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="459.61052169335136"
        y="751.14"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.65651156412736s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="409.57157386268653"
        y="837.8100000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.3953752353165694s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="559.6884173546812"
        y="231.12"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9694717338550793s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="509.64946952401635"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.34836983891543216s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="559.6884173546812"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.5627903521380304s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="509.64946952401635"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8828750035003416s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="559.6884173546812"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.465091032583317s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="509.64946952401635"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.02566271834066791s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="509.64946952401635"
        y="837.8100000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.7820145684271251s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="659.7663130160108"
        y="57.78"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.7644639358819214s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="659.7663130160108"
        y="231.12"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.14676498319185383s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="609.727365185346"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.726058267903495s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="659.7663130160108"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7797756895881522s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="609.727365185346"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.41847652119337564s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="659.7663130160108"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.39708316645189523s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="609.727365185346"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.131986142844874s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="709.8052608466758"
        y="144.45"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8366312476574649s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="759.8442086773406"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8297047067011043s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="709.8052608466758"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.2960441264400053s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="759.8442086773406"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8026148585072121s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="709.8052608466758"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8557499522426233s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="759.8442086773406"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9646116582373048s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="709.8052608466758"
        y="664.47"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8520396690995446s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="809.8831565080055"
        y="144.45"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.7349187434091697s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="859.9221043386704"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.7140658254744303s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="809.8831565080055"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8378591390240375s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="859.9221043386704"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.0164580588979018s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="809.8831565080055"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.2109997962234251s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="859.9221043386704"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.013629105776894246s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="809.8831565080055"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.1632065781750809s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="859.9221043386704"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8441743572162808s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="960.0000000000001"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.17927564998620493s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="909.9610521693352"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.16124694384516536s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="960.0000000000001"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9784425635980374s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="909.9610521693352"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7389306032818408s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="960.0000000000001"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.0515325008020073s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="909.9610521693352"
        y="664.47"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6257845630100296s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="909.9610521693352"
        y="837.8100000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.1568837057055017s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1060.0778956613296"
        y="57.78"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.9983637738678328s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1060.0778956613296"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.89505985097141s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1010.0389478306647"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.5258581177833013s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1060.0778956613296"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9264596544465653s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1010.0389478306647"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8709099881434628s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1060.0778956613296"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.369329711017559s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1010.0389478306647"
        y="664.47"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.06563556485398037s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1060.0778956613296"
        y="751.14"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9773054632231766s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1010.0389478306647"
        y="837.8100000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.4116220314767882s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1060.0778956613296"
        y="924.48"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.3567766838732025s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1160.1557913226595"
        y="57.78"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8460354798051903s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1110.1168434919946"
        y="144.45"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.493460927331661s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1160.1557913226595"
        y="231.12"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.2832166426941654s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1110.1168434919946"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.7477696878550661s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1160.1557913226595"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.24363114961556764s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1110.1168434919946"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6562030609558605s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1160.1557913226595"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.3728152862769023s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1110.1168434919946"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.6248934942328015s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1160.1557913226595"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7916923166742555s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1210.1947391533242"
        y="144.45"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.9991506805113586s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1260.2336869839892"
        y="231.12"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4814661936806988s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1210.1947391533242"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.334844006861048s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1260.2336869839892"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.7516745699889515s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1210.1947391533242"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6557504006452235s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1260.2336869839892"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.094591581261057s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1210.1947391533242"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.2184278966865958s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1260.2336869839892"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.3513039382547851s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1360.3115826453188"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.4338011344036117s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1310.2726348146539"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.4527581201285704s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1360.3115826453188"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.9609214044236487s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1310.2726348146539"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.0118645992053725s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1360.3115826453188"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.0259840853173152s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1310.2726348146539"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.3104007343537618s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1360.3115826453188"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.5891163545349714s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1410.3505304759838"
        y="144.45"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.21829116565404583s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1460.3894783066487"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.1355284592530417s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1410.3505304759838"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.94665881733938s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1460.3894783066487"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.9953731862330928s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1410.3505304759838"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.516616487437564s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1460.3894783066487"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.2638712465940785s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1410.3505304759838"
        y="664.47"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.050257426503485636s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1460.3894783066487"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.468408554436122s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1410.3505304759838"
        y="837.8100000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.39430166205066763s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1460.3894783066487"
        y="924.48"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6616692556040218s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1510.4284261373134"
        y="144.45"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.86154256872923s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1560.4673739679783"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8242718132948004s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1510.4284261373134"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.2175083923330643s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1560.4673739679783"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7270984332261183s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1510.4284261373134"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.2962229012313609s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1560.4673739679783"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.5672814836866213s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1510.4284261373134"
        y="664.47"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9255349690852333s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1510.4284261373134"
        y="837.8100000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.5368241272178422s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1560.4673739679783"
        y="924.48"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.1936969359363734s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1610.506321798643"
        y="144.45"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.1803437481739527s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1660.545269629308"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.135959113600768s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1610.506321798643"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.40837227734848147s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1660.545269629308"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.15336481196042806s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1610.506321798643"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.887312424234966s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1660.545269629308"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.1915912765459487s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1610.506321798643"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.18896492193282466s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1610.506321798643"
        y="837.8100000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.3409858051349102s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1660.545269629308"
        y="924.48"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6151403539533953s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1760.6231652906379"
        y="57.78"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.3748477430682464s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1710.584217459973"
        y="317.79"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7873859697201908s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1760.6231652906379"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.5075160746018743s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1710.584217459973"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.006693192588248298s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1760.6231652906379"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.0855262625587363s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1710.584217459973"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7578620262581142s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1760.6231652906379"
        y="924.48"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.8656130976447138s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1810.6621131213026"
        y="144.45"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.613395874676118s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1810.6621131213026"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.8114478476438038s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1860.7010609519675"
        y="404.46000000000004"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.1255639822882757s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1810.6621131213026"
        y="491.13"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6799218509346328s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1860.7010609519675"
        y="577.8000000000001"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.7915675942796221s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1810.6621131213026"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.1945668745713287s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1860.7010609519675"
        y="751.14"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.0648643756890972s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1810.6621131213026"
        y="837.8100000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6102834534890933s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1910.7400087826322"
        y="144.45"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.0661932470654443s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1960.778956613297"
        y="231.12"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9974440949297807s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1910.7400087826322"
        y="317.79"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.5207572994725451s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1960.778956613297"
        y="404.46000000000004"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.9524123387569379s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1910.7400087826322"
        y="491.13"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.6911207927250382s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1960.778956613297"
        y="577.8000000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.019559081100915865s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1910.7400087826322"
        y="664.47"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-0.023611225986051032s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1960.778956613297"
        y="751.14"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4768860202278926s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1910.7400087826322"
        y="837.8100000000001"
        fill="#0a0a0a"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.4868712218491758s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
      <use
        href="#bestagon-342510403153130245143"
        x="1960.778956613297"
        y="924.48"
        fill="#8d95c9"
      >
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          begin="-1.9497503020309783s"
          values="#0a0a0a;#8d95c9"
          keyTimes="0;1"
        >
          {" "}
        </animate>
      </use>
    </g>
  </svg>
);
