import React from "react";
import classNames from 'classnames';
import { utils as ethersUtils } from "ethers";

import 'assets/css/roulette.css';
import { Image, Skeleton } from "antd";
import {AliensNFTs, s0uImageFallback, s0uNFTs} from "utils/constant";
import { convertNFTImage } from "utils/web3.utils";

const { getAddress } = ethersUtils;
const TablePlayers = props => {
  const {
    isLoading,
    data = [],
  } = props;

  return (
    <div style={{ marginTop: 56 }} className={classNames({
      ["nftBoxGrid"]: true,
      ["nftBoxPlayerGrid"]: true,
    })}>
      <Skeleton loading={isLoading}>
        {data?.map((nft) => {
          const tokenAddress = getAddress(nft.tokenAddress);
          return (
            <div
              className={classNames({
                ["nftBox"]: true,
              })}
              style={{ display: "flex", padding: "8px", width: "100%", gap: "8px" }}
              key={nft.id}
            >
              <div>
                {
                  s0uNFTs[tokenAddress] === s0uNFTs.HUMANMUSIC
                    ? <video style={{ height: "100%", width: "auto", maxWidth: '100%' }} autoPlay muted controls={false}>
                      <source src={convertNFTImage(tokenAddress, nft.tokenId, '') || "error"} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    : <Image
                      preview={false}
                      src={convertNFTImage(tokenAddress, nft.tokenId, nft.image) || "error"}
                      fallback={s0uImageFallback[tokenAddress]}
                      alt={nft?.metadata?.name || nft?.name}
                    />
                }
              </div>
              <div className="nftBoxPlayerInfo">
                <span>{nft?.name || `${AliensNFTs[tokenAddress] ? '' : 'S0U - '}${s0uNFTs[tokenAddress]} #${nft.tokenId}`}</span>
                {
                  nft.numOfNFT
                    ? <span>NFTs: {nft.numOfNFT}</span>
                    : null
                }
              </div>
            </div>
          )
        })}
      </Skeleton >
    </div >
  );
}

export default TablePlayers;
