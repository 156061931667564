import moment from "moment";
import Moralis from "moralis-v1";
import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";

export const useTournament = () => {
  const [loading, setLoading] = useState(false);
  const [activeTournament, setActiveTournament] = useState();
  const [upcomingTournament, setUpcomingTournament] = useState();

  const {
    data: tournaments,
    error,
    isLoading,
    fetch: fetchTournament,
  } = useMoralisQuery(
    "Tournament",
    query =>
      query
        // .lessThanOrEqualTo('startTime', currentDate)
        // .greaterThanOrEqualTo('endTime', currentDate)
        .ascending("startTime")
        .notEqualTo("objectId", ""),
    [],
    {
      live: true,
      onLiveEnter: (entity, all) => {
        return [...all, entity];
      },
      onLiveCreate: (entity, all) => {
        return [...all, entity];
      },
      onLiveDelete: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveLeave: (entity, all) => all.filter(e => e.id !== entity.id),
      onLiveUpdate: (entity, all) => {
        return all.map(e => (e.id === entity.id ? entity : e));
      },
    }
  );

  const getTournamentDetail = async _id => {
    setLoading(true);
    const tournamentObject = Moralis.Object.extend("Tournament");
    const query = new Moralis.Query(tournamentObject);
    const result = await query.get(_id);
    setLoading(false);
    if (result) {
      setActiveTournament({
        ...result,
        ...result.attributes,
      });
      return {
        ...result,
        ...result.attributes,
      };
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (tournaments.length > 0) {
      const currentDate = moment().utc().valueOf();
      const validTournament = tournaments.find(
        item =>
          item.attributes.startTime <= currentDate &&
          currentDate <= item.attributes.endTime
      );
      console.log("=====use Tournament: ", validTournament, tournaments);
      if (validTournament) {
        setActiveTournament({
          ...validTournament,
          ...validTournament.attributes,
        });
      } else {
        setActiveTournament(null);
      }

      const nextTournament = tournaments.find(
        item =>
          item.attributes.startTime > currentDate &&
          currentDate < item.attributes.endTime
      );
      setUpcomingTournament(() =>
        nextTournament
          ? {
              ...nextTournament,
              ...nextTournament.attributes,
            }
          : null
      );
    }
  }, [tournaments]);

  return {
    activeTournament,
    upcomingTournament,
    error,
    isLoading: isLoading || loading,
    fetchTournament,
    getTournamentDetail,
  };
};
